import { createSelector } from 'reselect'

import {
  getLocalizedStringsEntities,
  getLocalizedStringValuesEntities,
  getLanguagesEntities
} from '../entities/selectors'

export const getLocalizedStrings = createSelector(
  (state) => state.localizedStrings.localizedStrings,
  getLocalizedStringsEntities,
  (localizedStrings, entities) => localizedStrings.map((o) => entities[o])
)

export const getCreateLocalizedStringLoading = createSelector([
  (state) => state.localizedStrings.createLocalizedString
],
  (loading) => loading
)

export const getCreateLocalizedStringError = createSelector([
  (state) => state.localizedStrings.createLocalizedStringError
],
  (error) => error
)

export const getUpdateLocalizedStringLoading = createSelector([
  (state) => state.localizedStrings.updateLocalizedString
],
  (loading) => loading
)

export const getUpdateLocalizedStringError = createSelector([
  (state) => state.localizedStrings.updateLocalizedStringError
],
  (error) => error
)

export const getDeleteLocalizedStringLoading = createSelector([
  (state) => state.localizedStrings.deleteLocalizedStringLoading
],
  (error) => error
)

export const makeGetTranslatedLanguages = () => (
  createSelector(
    getLocalizedStringsEntities,
    getLocalizedStringValuesEntities,
    getLanguagesEntities,
    (_, localizedStringId) => localizedStringId,
    (localizedStringEntities, localizedStringValueEntities, languageEntities, id) => {
      return (
        localizedStringEntities[id].localizedStringValues
          .map(o => localizedStringValueEntities[o])
          .filter(o => o.value.length > 0)
          .map(o => languageEntities[o.language].id)
      ) 
    }
  )
)
