const packageName = 'localizedStringValues'

export const UPDATE_LOCALIZED_STRING_VALUE = `${packageName}/UPDATE_LOCALIZED_STRING_VALUE`
export const UPDATE_LOCALIZED_STRING_VALUE_SUCCESS = `${packageName}/UPDATE_LOCALIZED_STRING_VALUE_SUCCESS`
export const UPDATE_LOCALIZED_STRING_VALUE_FAIL = `${packageName}/UPDATE_LOCALIZED_STRING_VALUE_FAIL`

export const BULK_UPDATE_LOCALIZED_STRING_VALUE = `${packageName}/BULK_UPDATE_LOCALIZED_STRING_VALUE`
export const BULK_UPDATE_LOCALIZED_STRING_VALUE_SUCCESS = `${packageName}/BULK_UPDATE_LOCALIZED_STRING_VALUE_SUCCESS`
export const BULK_UPDATE_LOCALIZED_STRING_VALUE_FAIL = `${packageName}/BULK_UPDATE_LOCALIZED_STRING_VALUE_FAIL`

export const GOOGLE_TRANSLATE = `${packageName}/GOOGLE_TRANSLATE`
export const GOOGLE_TRANSLATE_SUCCESS = `${packageName}/GOOGLE_TRANSLATE_SUCCESS`
export const GOOGLE_TRANSLATE_FAIL = `${packageName}/GOOGLE_TRANSLATE_FAIL`
