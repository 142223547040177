import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { put } from 'axios';
import Dropzone from 'react-dropzone'
import { Line } from 'rc-progress'

import { useTranslate } from 'polyglot-react-redux-sdk'
import useFetchTranslations from 'hooks/fetchTranslations.js'

import { actions as projectsActions } from 'store/reducers/projects'
import { actions as assetsActions, selectors as assetsSelectors } from 'store/reducers/assets'

import Contextualbar from 'components/Contextualbar'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import TextField from 'components/TextField'

const ProjectSettings = (props) => {
  const t = useTranslate('projectSettings')

  const projectFetched = props.location.state && props.location.state.projectId
  const projectSlug = props.match.params.slug

  const [projectId, setProjectId] = useState(projectFetched || null)

  const dispatch = useDispatch()
  const getProject = useCallback(projectSlug =>
    dispatch(projectsActions.getProject(projectSlug), [dispatch]))

    const [presignUrl, setPresignUrl] = useState(null)

    const [filename, setFilename] = useState(null)
    const [fileUrl, setFileUrl] = useState(null)
    const getPresignUrl = useCallback((projectSlug, filename) =>
    dispatch(projectsActions.getPresignUrl(projectSlug, filename), [dispatch]))

    const project = useFetchTranslations(projectId, projectSlug).project

    const [outputMode, setOutputMode] = useState(null)
    const [prefix, setPrefix] = useState(null)
    const [inter, setInter] = useState(null)
    const [suffix, setSuffix] = useState(null)

    const updateOutputMode = useCallback((projectId, outputMode, prefix, inter, suffix) =>
    dispatch(projectsActions.updateOutputMode(projectId, outputMode, prefix, inter, suffix)), [dispatch])

    const outputModeOptions = [
      {'value': 'flat', 'label': 'Flat'}
    ]

    const handleUpdateOutputMode = useCallback(() => {
      updateOutputMode(projectId, outputMode, prefix, inter, suffix)
    })

    const getAssets = useCallback(() =>
    dispatch(assetsActions.getAssets(), [dispatch]))

    const postAsset = useCallback((fileUrl, projectId) =>
    dispatch(assetsActions.postAsset(fileUrl, projectId)), [dispatch])

    // const assets = (project && project.assets) ? project.assets : []
    const assets = useSelector(assetsSelectors.getAssets)

    const [uploadProgress, setUploadProgress] = useState(0)

    useEffect(() => {
      if (!projectFetched) {
        getProject(projectSlug).then((project) => {
          setProjectId(project.data.id)

        })

      }
    }, [])


    useEffect(() => {
      getAssets()
    }, [])

    const handleDrop =  ([acceptedFiles]) => {

      setFilename(acceptedFiles.name)

      getPresignUrl(projectSlug, acceptedFiles.name).then((presignedUrl) => {
        setPresignUrl(presignedUrl.meta.url)
        console.log(presignUrl)
        console.log(filename)
        console.log(fileUrl)

        const config = {
          headers: { 'content-type': '*' },
          onUploadProgress: progressEvent => {
            var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
            if (percent >= 100) {
              setUploadProgress(100);
            } else {
              setUploadProgress(percent);
            }
          }
        };

        put(presignedUrl.meta.url, acceptedFiles, config)
        .then(function(response) {

          const fileUrl = presignedUrl.meta.url.split('?')[0]
          setFileUrl(fileUrl)
          postAsset(fileUrl, projectId).then(() => {

            //message

            console.log("oi")

          })
        })
        .catch(function(error) {
          console.log(error);
          setUploadProgress(0);
        });
      })
    }

    const handleSubmitLocalizedStringValues = useCallback(() =>
        dispatch(projectsActions.publishStrings(projectSlug)), [dispatch])

    return (
      <div className="project-settings-page">
        <Contextualbar
          project={project}
          activeOption="settings"
          />
        { !project
          ? <div>LOADING</div>
          : <div className="project-settings-content">

          {(project && project.ressourceLink) &&

            <a className="update-localized-string-title" href={project.ressourceLink} rel="noopener noreferrer" target="_blank">
              {project.ressourceLink}
            </a>

          }


          <div className="button-container">
            <Button
              text={t('submit')}
              type="black"
              action={handleSubmitLocalizedStringValues}
              />

          </div>

          <div className="output-mode-dropdown">
            <p className="update-localized-string-title">{t('selectMode')}</p>

            <Dropdown
              type="secondary"
              placeholder={t('outupModeDropdown.placeholder')}
              options={outputModeOptions}
              onChange={(option) => {
                setOutputMode(option.value)
              }}
              value={outputMode}
              />

            <p className="update-localized-string-title">{t('updatePrefix')}</p>

            <div className="field">
              <TextField
                value={prefix}
                type="text"
                placeholder={t('updatePrefix')}
                onChange={(prefix) => {
                  setPrefix(prefix.target.value)
                }}
                />
            </div>

            <p className="update-localized-string-title">{t('updateInter')}</p>

            <div className="field">
              <TextField
                value={inter}
                type="text"
                placeholder={t('updateInter')}
                onChange={(inter) => {
                  setInter(inter.target.value)
                }}
                />
            </div>

            <p className="update-localized-string-title">{t('updateSuffix')}</p>

            <div className="field">
              <TextField
                value={suffix}
                type="text"
                placeholder={t('updateSuffix')}
                onChange={(suffix) => {
                  setSuffix(suffix.target.value)
                }}
                />
            </div>

            <div className="button-container">
              <Button
                text={t('updateOutputMode')}
                action={handleUpdateOutputMode}
                type="black"
                />
            </div>

          </div>
          <div className="polygon">
            <Dropzone onDrop={acceptedFiles => handleDrop(acceptedFiles)}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </section>
              )}

            </Dropzone>

            { (uploadProgress > 0 && uploadProgress !== 100) &&
              <Line percent={uploadProgress} strokeWidth='1' strokeColor='#2db7f5' strokeLinecap='square' />
            }

            <hr />

            { assets.map((asset, index) => (
              <div key={index}>{asset.attributes.url}</div>
            ))
          }
        </div>
      </div>
    }
  </div>
)
}

export default ProjectSettings
