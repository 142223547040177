import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useTranslate, usePolyglot } from 'polyglot-react-redux-sdk'

import logoSimple from 'assets/images/logo-simple.svg'
import logoFullInverted from 'assets/images/logo-full-inverted.svg';
import screenshot from 'assets/images/screenshot.png'

import defaultPhrases from 'utils/defaultPhrases'
const isDev = process.env.REACT_APP_ENV=== 'dev';
const Landing = () => {
  const t = useTranslate('landing')

  const { locale, setLocale } =  usePolyglot({
    defaultUrl: 'https://cdn.polyglot.cloud/c81e728d9d4c2f636f067f89cc14862c/all.json',
    defaultLocale: 'en-us',
    useDefaultPhrases: isDev,
    defaultPhrases,
  })
  const renderLocaleSelector = useCallback((language, loc) => {
    const classes = `translation-demo-selector ${locale === loc ? 'selected' : ''}`

    return (
      <div className={classes} onClick={() => setLocale(loc)}>
        <p>{language}</p>
      </div>
    )
  }, [locale, setLocale])

  return (
    <div className="landing-page">
      <div className="header">
        <div className="header-left"></div>
        <div className="header-center">
          <img src={logoSimple} alt="logo" className="logo"/>

          <div className="right-section">
            <a href="mailto:hello@gaspardbruno.com" className="contact">{t('contact')}</a>
            <Link to="/login" className="login">{t('login')}</Link>
          </div>
        </div>
        <div className="header-right"></div>
      </div>

      <div className="content">
        <div className="section section-1">
          <div className="section-background">
            <div className="section-background-top"></div>
            <div className="section-background-bottom"></div>
          </div>

          <div className="section-content">
            <h1>{t('wespeak')}</h1>

            <div className="screenshot-container">
              <img src={screenshot} alt="sreenshot" className="screenshot"/>
            </div>

            <div className="translation-demo-container">
              <p className="translation-demo-title">{t('translationdemo.title')}</p>

              <h2 className="translation-demo-description">{t('translationdemo.description')}</h2>

              <div className="translation-demo-selector-container">
                { renderLocaleSelector('En', 'en-us') }
                { renderLocaleSelector('Pt', 'pt') }
                { renderLocaleSelector('Fr', 'fr') }
                { renderLocaleSelector('Es', 'es') }
              </div>

              <div className="translation-demo-try-container">
                <p>{t('translationdemo.try')}</p>
                <p>{t('translationdemo.hover')}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-2">
          <div className="section-2-section left-section">
            <div className="section-empty"></div>
            <div className="section-center">
              <p className="section-title">{t('fromdevelopers')}</p>

              <h2>{t('opensource')}</h2>

              <div className="middle-info">
                <p>{t('wemake')}</p>
                <p>{t('letyour')}</p>
              </div>

              <div className="sdk-list">
                <a href="https://github.com/Gaspard-Bruno/polyglot-react-redux-sdk">
                  <p className="sdk active">{t('sdk.reactredux')}</p>
                </a>
                <p className="divider">|</p>
                <p className="sdk">{t('sdk.ios')}</p>
                <p className="divider">|</p>
                <p className="sdk">{t('sdk.android')}</p>
                <p className="divider">|</p>
                <p className="sdk">{t('sdk.ror')}</p>
              </div>
            </div>
            <div className="left-section-empty"></div>
          </div>

          <div className="section-2-section right-section">
            <div className="section-empty"></div>
            <div className="section-center">
              <p className="section-title">{t('totranslators')}</p>

              <h2>{t('translatorssystem')}</h2>

              <div className="middle-info">
                <p>{t('onesource')}</p>
                <p>{t('backedby')}</p>
              </div>
            </div>
            <div className="right-section-empty"></div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="empty-section"></div>

        <div className="logo-section">
          <img src={logoFullInverted} alt="full logo" className="logo-full" />
        </div>

        <div className="center-info-section">
          <div className="social-media-links">
            <a href="https://www.instagram.com/gaspardbruno">{t('instagram')}</a>
            <p>/</p>
            <a href="https://github.com/Gaspard-Bruno">{t('github')}</a>
            <p>/</p>
            <a href="https://twitter.com/gaspardbruno">{t('twitter')}</a>
            <p>/</p>
            <a href="https://www.facebook.com/gaspardbrunoagency">{t('facebook')}</a>
          </div>

          <p className="copyright">{t('copyright')}</p>
        </div>

        <div className="empty-section"></div>

        <div className="right-info-section">
          <div className="mailto-container">
            <a href="mailto:support@gaspardbruno.com">{t('support')}</a>
            <a href="mailto:business@gaspardbruno.com">{t('business')}</a>
          </div>

          <p className="all-rights">{t('rights')}</p>
        </div>
      </div>
    </div>
  )
}

export default Landing
