import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { useTranslate } from 'polyglot-react-redux-sdk'

import { actions as authActions, selectors as authSelectors } from 'store/reducers/auth'

import { clickableElement } from 'utils/accessibility'

const ProjectMenu = ({ project, activeOption, collapsed }) => {
  const t = useTranslate('sidebar')

  const { name, slug } = project

  const authenticated = useSelector(authSelectors.isAuthenticated)

  const dispatch = useDispatch()
  const logout = useCallback(() => dispatch(authActions.logout()), [dispatch])

  const topClassName = ['contextualbar-top', collapsed && 'collapsed'].filter((c) => c).join(' ')
  const translationsClassName = ['contextualbar-top-option', activeOption === 'translations' && 'active']
      .filter((c) => c).join(' ')
  const peopleClassName = ['contextualbar-top-option', activeOption === 'people' && 'active']
      .filter((c) => c).join(' ')
  const settingsClassName = ['contextualbar-top-option', activeOption === 'settings' && 'active']
      .filter((c) => c).join(' ')
  const bottomClassName = ['contextualbar-bottom', collapsed && 'collapsed'].filter((c) => c).join(' ')

  return (
    <>
      <div className={topClassName}>
        <p className="contextualbar-title">{!collapsed ? name : name.match(/\b\w/g).join('')}</p>

        <p className="contextualbar-subtitle">Desktop Project</p>

        <div className="contextualbar-top-options">
          <div className="contextualbar-top-options-content">
            <Link
              to={{ pathname: `/projects/${slug}/translations`, state: { projectId: project.id } }}
              className={translationsClassName}
            >
              <svg
                className="contextualbar-top-option-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.95999 9.11999C8.06456 9.11999 8.95999 8.22456 8.95999 7.12C8.95999 6.01543 8.06456 5.12 6.95999 5.12C5.85542 5.12 4.95999 6.01543 4.95999 7.11999C4.95999 8.22456 5.85542 9.11999 6.95999 9.11999ZM15.68 19.36C17.7124 19.36 19.36 17.7124 19.36 15.68C19.36 13.6476 17.7124 12 15.68 12C13.6476 12 12 13.6476 12 15.68C12 17.7124 13.6476 19.36 15.68 19.36Z"
                  fill={activeOption === 'translations' ? '#000' : '#858AA4'}
                />
              </svg>
              <p className="contextualbar-top-option-label">{t('project.translations')}</p>
            </Link>

            <Link
              to={{ pathname: `/projects/${slug}/people`, state: { projectId: project.id } }}
              className={peopleClassName}
            >
              <svg
                className="contextualbar-top-option-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.72001 9.27999C7.86877 9.27999 8.80001 8.34875 8.80002 7.2C8.80002 6.05124 7.86877 5.12 6.72002 5.12C5.57126 5.12 4.64001 6.05124 4.64001 7.19999C4.64001 8.34875 5.57126 9.27999 6.72001 9.27999ZM17.28 9.27999C18.4288 9.27999 19.36 8.34875 19.36 7.2C19.36 6.05124 18.4288 5.12 17.28 5.12C16.1313 5.12 15.2 6.05124 15.2 7.19999C15.2 8.34875 16.1313 9.27999 17.28 9.27999ZM19.36 16.8C19.36 17.9488 18.4288 18.88 17.28 18.88C16.1313 18.88 15.2 17.9488 15.2 16.8C15.2 15.6512 16.1313 14.72 17.28 14.72C18.4288 14.72 19.36 15.6512 19.36 16.8ZM6.72001 18.88C7.86877 18.88 8.80001 17.9488 8.80002 16.8C8.80002 15.6512 7.86877 14.72 6.72002 14.72C5.57126 14.72 4.64001 15.6512 4.64001 16.8C4.64001 17.9488 5.57126 18.88 6.72001 18.88Z"
                  fill={activeOption === 'people' ? '#000' : '#858AA4'}
                />
              </svg>
              <p className="contextualbar-top-option-label">{t('project.people')}</p>
            </Link>

            <Link
              to={{ pathname: `/projects/${slug}/settings`, state: { projectId: project.id } }}
              className={settingsClassName}
            >
              <svg
                className="contextualbar-top-option-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.36 4.8C8.50875 4.8 9.44 5.73125 9.44 6.88C9.44 8.02875 8.50875 8.96 7.36 8.96C6.21125 8.96 5.28 8.02875 5.28 6.88C5.28 5.73125 6.21125 4.8 7.36 4.8ZM15.04 12.64H9.44V11.68H15.04V12.64ZM19.04 17.2C19.04 16.0954 18.1446 15.2 17.04 15.2C15.9354 15.2 15.04 16.0954 15.04 17.2C15.04 18.3046 15.9354 19.2 17.04 19.2C18.1446 19.2 19.04 18.3046 19.04 17.2Z"
                  fill={activeOption === 'settings' ? '#000' : '#858AA4'}
                />
              </svg>
              <p className="contextualbar-top-option-label">{t('project.settings')}</p>
            </Link>
          </div>
        </div>
      </div>

      <div className={bottomClassName}>
        { authenticated &&
          <div onClick={logout} className="logout-button" { ...clickableElement(t('signout')) }>
            <p className="contextualbar-bottom-option-label">{t('signout')}</p>
          </div>
        }
      </div>
    </>
  )
}

export default ProjectMenu
