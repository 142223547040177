import produce from 'immer'

import * as actionTypes from './actionTypes'


const initialState = {
  localizedStrings: [],
  localizedStringsLoading: false,
  localizedStringsError: null,
  localizedStringError: null,
  createLocalizedString: false,
  createLocalizedStringError: null,
  updateLocalizedString: false,
  updateLocalizedStringError: null,
  deleteLocalizedStringLoading: false,
  deleteLocalizedStringError: null,
}

const reducer = (state = initialState, action = {}) => {
  return produce(state, draft => {
    switch (action.type) {
      // GET LOCALIZED_STRINGS
      case actionTypes.GET_LOCALIZED_STRINGS:
        draft.localizedStringsLoading = true
        break
      case actionTypes.GET_LOCALIZED_STRINGS_SUCCESS:
        draft.localizedStringsLoading = false
        draft.localizedStrings = action.result.data.map((d) => d.id)
        break
      case actionTypes.GET_LOCALIZED_STRINGS_FAIL:
        draft.localizedStringsLoading = false
        draft.localizedStringsError = { status: action.error.status }
        break

      // CREATE LOCALIZED_STRING
      case actionTypes.CREATE_LOCALIZED_STRING:
        draft.createLocalizedString = true
        break
      case actionTypes.CREATE_LOCALIZED_STRING_SUCCESS:
        draft.createLocalizedString = false
        draft.localizedStrings = [action.result.data.id].concat(draft.localizedStrings)
        break
      case actionTypes.CREATE_LOCALIZED_STRING_FAIL:
        draft.createLocalizedString = false
        draft.createLocalizedStringError = {
          message: action.error.meta.message.key,
          status: action.error.meta.status,
        }
        break

      // UPDATE LOCALIZED_STRING
      case actionTypes.UPDATE_LOCALIZED_STRING:
        draft.updateLocalizedString = true
        break
      case actionTypes.UPDATE_LOCALIZED_STRING_SUCCESS:
        draft.updateLocalizedString = false
        break
      case actionTypes.UPDATE_LOCALIZED_STRING_FAIL:
        draft.updateLocalizedString = false
        draft.updateLocalizedStringError = {
          message: action.error.meta.message.key,
          status: action.error.meta.status,
        }
        break

      // DELETE LOCALIZED_STRING
      case actionTypes.DELETE_LOCALIZED_STRING:
        draft.deleteLocalizedStringLoading = true
        break
      case actionTypes.DELETE_LOCALIZED_STRING_SUCCESS:
        draft.deleteLocalizedStringLoading = false
        draft.localizedStrings = draft.localizedStrings.filter((localizedStringId) => (
          localizedStringId !== action.result.data.id
        ))
        break
      case actionTypes.DELETE_LOCALIZED_STRING_FAIL:
        draft.deleteLocalizedStringLoading = false
        draft.deleteLocalizedStringError = { status: action.error.status }
        break

      default:
        break
    }
  })
}

export default reducer
