import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslate } from 'polyglot-react-redux-sdk'

import { actions as authActions, selectors as authSelectors } from 'store/reducers/auth'

import TextField from 'components/TextField'
import Button from 'components/Button'

import logo from 'assets/images/logo.svg'
import logoFull from 'assets/images/logo-full.svg'

const Login = () => {
  const t = useTranslate('login')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()
  const login = useCallback((email, password) => dispatch(authActions.login(email, password)), [dispatch])

  const loginLoading = useSelector(authSelectors.getLoginLoading)
  const loginError = useSelector(authSelectors.getLoginError)

  const handleChangeEmail = useCallback((value) => {
    setEmail(value.target.value)
  }, [])

  const handleChangePassword = useCallback((value) => {
    setPassword(value.target.value)
  }, [])

  const handleSubmitForm = useCallback(() => {
    login(email, password)
  }, [email, password])

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleSubmitForm()
    }
  }, [handleSubmitForm])

  return (
    <div className="login-page row" onKeyPress={handleKeyPress}>
      <div className="left-section"></div>

      <div className="center-section">
        <Link to="/">
          <img src={logo} alt="logo" className="logo"/>
        </Link>

        <div className="form">
          <div className="field">
            <TextField
              value={email}
              type="email"
              placeholder={t('email.placeholder')}
              error={loginError}
              onChange={handleChangeEmail} />
          </div>

          <div className="field">
            <TextField
              value={password}
              type="password"
              placeholder={t('password.placeholder')}
              error={loginError}
              onChange={handleChangePassword} />
          </div>

          <div className="form-error">
            { loginError &&
              <p>{t(`error.${loginError.status}`)}</p>
            }
          </div>

          <div className="button-container">
            <Button text={t('submit')} action={handleSubmitForm} type="black" loading={loginLoading} />
          </div>
        </div>

        <img src={logoFull} alt="full logo" className="logo-full" />
      </div>

      <div className="right-section"></div>
    </div>
  )
}

export default Login
