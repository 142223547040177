import produce from 'immer'

import * as actionTypes from './actionTypes'


const initialState = {
  updateLocalizedStringValue: false,
  updateLocalizedStringValueError: null,
  translatedValue: "",
  translating: false,
  translatingError: null,
}

const reducer = (state = initialState, action = {}) => {
  return produce(state, draft => {
    switch (action.type) {
      // BULK UPDATE LOCALIZED_STRING_VALUE
      case actionTypes.BULK_UPDATE_LOCALIZED_STRING_VALUE:
        draft.updateLocalizedStringValue = true
        break
      case actionTypes.BULK_UPDATE_LOCALIZED_STRING_VALUE_SUCCESS:
        draft.updateLocalizedStringValue = false
        break
      case actionTypes.BULK_UPDATE_LOCALIZED_STRING_VALUE_FAIL:
        draft.updateLocalizedStringValue = false
        draft.updateLocalizedStringValueError = {
          message: action.error.meta.message.key,
          status: action.error.meta.status,
        }
        break
      // GOOGLE TRANSLATE
      case actionTypes.GOOGLE_TRANSLATE:
        draft.translating = true
        break
      case actionTypes.GOOGLE_TRANSLATE_SUCCESS:
        draft.translating = false
        draft.translatedValue = action.result.translations[0].translatedText
        break
      case actionTypes.GOOGLE_TRANSLATE_FAIL:
        draft.translating = false
        draft.translatingError = { status: action.error.status }
        break

      default:
        break
    }
  })
}

export default reducer
