import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslate } from 'polyglot-react-redux-sdk'

import { selectors as languagesSelectors } from 'store/reducers/languages'
import {
  actions as localizedStringsActions, selectors as localizedStringsSelectors
} from 'store/reducers/localizedStrings'
import { selectors as projectsSelectors } from 'store/reducers/projects'

import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import TextField from 'components/TextField'

const UpdateMenu = ({ initialLocalizedStringKey, localizedStringId, projectLanguages=[],
      onCreateLanguage, onDeleteLanguage  }) => {
  const t = useTranslate(['translations'])

  const [localizedStringKey, setLocalizedStringKey] = useState(initialLocalizedStringKey)
  const [localizedStringKeyError, setLocalizedStringKeyError] = useState(null)
  const [createLanguageId, setCreateLanguageId] = useState(null)
  const [deleteLanguageId, setDeleteLanguageId] = useState(null)

  const updateLoading = useSelector(localizedStringsSelectors.getUpdateLocalizedStringLoading)
  const updateError = useSelector(localizedStringsSelectors.getUpdateLocalizedStringError)
  const updateSuccess = useSelector(state => state.localizedStrings.createLocalizedString)
  const deleteLocalizedStringLoading = useSelector(localizedStringsSelectors.getDeleteLocalizedStringLoading)

  const createProjectLanguageLoading = useSelector(projectsSelectors.getCreateProjectLanguageLoading)
  const deleteProjectLanguageLoading = useSelector(projectsSelectors.getDeleteProjectLanguageLoading)

  const availableLanguageOptions = useSelector((state) => languagesSelectors.getDropdownAvailableLanguages(state, projectLanguages))
  const projectLanguageOptions = useSelector((state) => languagesSelectors.getDropdownProjectLanguages(state, projectLanguages))

  const dispatch = useDispatch()
  const updateLocalizedString = useCallback((key, localizedStringId) =>
      dispatch(localizedStringsActions.updateLocalizedString(key, localizedStringId)), [dispatch])
  const deleteLocalizedString = useCallback((localizedStringId) =>
      dispatch(localizedStringsActions.deleteLocalizedString(localizedStringId)), [dispatch])

  const handleChangeLocalizedStringKey = useCallback((value) => {
    setLocalizedStringKey(value.target.value)
  }, [])

  const handleUpdateLocalizedString = useCallback(() => {
    const projectKeyValid = localizedStringKey && localizedStringKey.length > 0

    projectKeyValid
    ? updateLocalizedString(localizedStringKey, localizedStringId)
    : setLocalizedStringKeyError(true)
  }, [localizedStringKey, localizedStringId, updateLocalizedString])

  const handleDeleteLocalizedString = useCallback(() => {
    var result = window.confirm(t('deleteLocalizedStringConfirm'));
    if (result) {
      deleteLocalizedString(localizedStringId)
    }
  }, [deleteLocalizedString, localizedStringId, t])

  const handleSetCreateLanguageId = useCallback((option) => {
    setCreateLanguageId(option.value)
  })

  const handleSetDeleteLanguageId = useCallback((option) => {
    setDeleteLanguageId(option.value)
  })

  const handleCreateLanguage = useCallback(() => {
    onCreateLanguage(createLanguageId)
  })

  const handleDeleteLanguage = useCallback(() => {
    onDeleteLanguage(deleteLanguageId)
  })

  useEffect(() => {
    if (updateSuccess) setLocalizedStringKey('')
  }, [updateSuccess])

  useEffect(() => {
    if (createProjectLanguageLoading) setCreateLanguageId(null)
  }, [createProjectLanguageLoading])

  useEffect(() => {
    if (deleteProjectLanguageLoading) setDeleteLanguageId(null)
  }, [deleteProjectLanguageLoading])


  useEffect(() => {
    if (initialLocalizedStringKey !== localizedStringKey) {
      setLocalizedStringKey(initialLocalizedStringKey)
    }
  }, [initialLocalizedStringKey])

  return (
    <div className="update-localized-string">
      <div className="update-localized-string-left-section"></div>

      <div className="update-localized-string-center-section">
        <p className="update-localized-string-title">{t('updateLocalizedString')}</p>

        <div className="form">
          <div className="field">
            <TextField
              value={localizedStringKey}
              type="text"
              placeholder={t('localizedStringKey')}
              error={localizedStringKeyError}
              onChange={handleChangeLocalizedStringKey} />
          </div>

          <div className="form-error">
            { updateError &&
              <p>{t(`error.${updateError.status}`)}</p>
            }
          </div>

          <div className="button-container">
            <Button
              text={t('updateLocalizedString')}
              action={handleUpdateLocalizedString}
              type="black"
              loading={updateLoading} />
          </div>

          <div className="language-dropdowns">
            <div className="language-dropdown">
              <p className="update-localized-string-title">{t('addLanguage')}</p>

              <Dropdown
                type="secondary"
                placeholder={t('languageDropdown.placeholder')}
                options={availableLanguageOptions}
                onChange={handleSetCreateLanguageId}
                value={createLanguageId}
              />

              <div className="button-container">
                <Button
                  text={t('addLanguage')}
                  action={handleCreateLanguage}
                  type="black"
                  loading={createProjectLanguageLoading} />
              </div>
            </div>

            <div className="language-dropdown">
              <p className="update-localized-string-title">{t('deleteLanguage')}</p>

              <Dropdown
                type="secondary"
                placeholder={t('languageDropdown.placeholder')}
                options={projectLanguageOptions}
                onChange={handleSetDeleteLanguageId}
                value={deleteLanguageId}
              />

              <div className="button-container">
                <Button
                  text={t('deleteLanguage')}
                  action={handleDeleteLanguage}
                  type="black"
                  loading={deleteProjectLanguageLoading} />
              </div>
            </div>
          </div>

          <div className="button-container delete-button-container">
            <Button
              text={t('deleteLocalizedString')}
              action={handleDeleteLocalizedString}
              type="red"
              loading={deleteLocalizedStringLoading} />
          </div>
        </div>
      </div>

      <div className="update-localized-string-right-section"></div>
    </div>
  )
}

export default UpdateMenu
