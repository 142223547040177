import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslate } from 'polyglot-react-redux-sdk'

import { actions as projectsActions, selectors as projectsSelectors } from 'store/reducers/projects'
import { selectors as authSelectors } from 'store/reducers/auth'

import Button from 'components/Button'
import TextField from 'components/TextField'

import OwnershipListItem from './OwnershipListItem'
import ProjectListItem from './ProjectListItem'
import ProjectMenu from './ProjectMenu'

const Dashboard = () => {
  const t = useTranslate('dashboard')

  const [rightSectionOpen, setRightSectionOpen] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [openCreateProject, setOpenCreateProject] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [projectNameError, setProjectNameError] = useState(null)

  const dispatch = useDispatch()
  const getProjects = useCallback(() => dispatch(projectsActions.getProjects()), [dispatch])
  const createProject = useCallback(
    (name, organization) => dispatch(projectsActions.createProject(name, organization)),
    [dispatch])
  const deleteProject = useCallback(
    (slug, id) => dispatch(projectsActions.deleteProject(slug, id)),
    [dispatch])

  const projects = useSelector(projectsSelectors.getProjects)
  const createLoading = useSelector(projectsSelectors.getCreateProjectLoading)
  const createError = useSelector(projectsSelectors.getCreateProjectError)
  const user = useSelector(authSelectors.getUser)

  const projectsCount = projects.length

  const handleOpenCreateProject = useCallback(() => {
    setRightSectionOpen(true)
    setOpenCreateProject(true)
    setSelectedProject(null)
  }, [])

  const handleOpenSelectedProject = useCallback((project) => {
    setRightSectionOpen(true)
    setOpenCreateProject(false)
    setSelectedProject(project)
  }, [])

  const handleChangeProjectName = useCallback((value) => {
    setProjectName(value.target.value)
  }, [])

  const handleCreateProject = useCallback(() => {
    const projectNameValid = projectName && projectName.length > 0

    if (projectNameValid) {
      createProject(projectName, user.organization_ids[0])
    } else {
      if (!projectNameValid) {
        setProjectNameError(true)
      }
    }
  }, [projectName, user, createProject])

  const handleDeleteProject = useCallback((projectSlug, projectId) => {
    deleteProject(projectSlug, projectId)
  })

  useEffect(() => {
    getProjects()
  }, [])

  useEffect(() => {
    handleOpenSelectedProject(projects[0])
  }, [projectsCount])

  const className = ['dashboard-page', rightSectionOpen && 'right-section-open'].filter(c => c).join(' ')

  return (
    <div className={className}>
      <div className="left-section">
        { projectsCount ?
          <div className="project-list">
            { projects.map(project => (
              <ProjectListItem
                project={project}
                selected={selectedProject && selectedProject.id === project.id}
                onClick={handleOpenSelectedProject}
                key={`project-${project.id}`} />
            ))}
          </div>
          :
          <div className="project-empty">
            <p className="project-empty-title">{t('projectempty')}</p>
          </div>
        }
        

        <div className="add-project">
          <button onClick={handleOpenCreateProject}>+</button>
          <p>{t('addProject')}</p>
        </div>
      </div>

      <div className="right-section">
        { openCreateProject &&
          <div className="create-project">
            <div className="create-project-left-section"></div>

            <div className="create-project-center-section">
              <p className="create-project-title">{t('createProject')}</p>

              <div className="form">
                <div className="field">
                  <TextField
                    value={projectName}
                    type="text"
                    placeholder={t('projectName')}
                    error={projectNameError}
                    onChange={handleChangeProjectName} />
                </div>

                <div className="form-error">
                  { createError &&
                    <p>{t(`error.${createError.status}`)}</p>
                  }
                </div>

                <div className="button-container">
                  <Button
                    text={t('createProject')}
                    action={handleCreateProject}
                    type="black"
                    loading={createLoading} />
                </div>
              </div>
            </div>

            <div className="create-project-right-section"></div>
          </div>
        }

        { selectedProject &&
          <div className="project-detail">
            <div className="project-detail-left-section">
              <p className="project-detail-overview"></p>
            </div>

            <div className="project-detail-right-section">
              <div>
                <p className="project-detail-members">
                  {`${t('members')} (${selectedProject.projectOwnerships.length})`}
                </p>

                { selectedProject.projectOwnerships.map((ownershipId, index) => (
                  <OwnershipListItem
                    ownershipId={ownershipId}
                    key={`ownership-${index}`} />
                ))}
              </div>

              <ProjectMenu
                project={selectedProject}
                onDeleteClick={handleDeleteProject}
              />
            </div>
          </div>
        }
      </div>

      <div className="right-sidebar"></div>
    </div>
  )
}

export default Dashboard
