import React, { useState } from 'react'

const Switch = ({ initialChecked=false, label, onChange }) => {
  const [checked, setChecked] = useState(initialChecked)

  const handleChange = () => {
    onChange(!checked)
    setChecked(!checked)
  }

  return (
    <div className="switch-component">
      { label &&
        <p className="switch-label">{label}</p>
      }
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={handleChange}/>
        <span className="slider round"/>
      </label>
    </div>
  )
}

export default Switch
