import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectors as projectOwnershipsSelectors } from 'store/reducers/projectOwnerships'

import Dropdown from 'components/Dropdown'
import User from 'components/User'

const OwnershipListItem = ({ ownershipId }) => {
  const getProjectOwnership = useMemo(projectOwnershipsSelectors.makeGetProjectOwnership, [])
  const projectOwnership = useSelector((state) => getProjectOwnership(state, ownershipId))

  const dropDownOptions = [
    { value: 'Owner', label: 'Owner' },
  ]

  return (
    <div className="project-member-detail">
      <div className="project-member-detail-left">
        <User userId={projectOwnership.user} />
      </div>

      <div className="project-member-detail-right">
        <div className="project-member-role">
          <Dropdown
            type="secondary"
            // placeholder={'Pick role'}
            options={dropDownOptions}
            onChange={() => null}
            value={{ value: 'Owner', label: 'Owner' }}
          />
        </div>

        <div className="project-member-remove">
          <p className="">Remove</p>
        </div>
      </div>
    </div>
  )
}

export default OwnershipListItem
