import * as actionTypes from './actionTypes'

export const getAssets = () => ({
  types: [
    actionTypes.GET_ASSETS,
    actionTypes.GET_ASSETS_SUCCESS,
    actionTypes.GET_ASSETS_FAIL,
  ],
  promise: (client) => client.get('assets')
})

export const postAsset = (url, projectId) => ({
  types: [
    actionTypes.POST_ASSET,
    actionTypes.POST_ASSET_SUCCESS,
    actionTypes.POST_ASSET_FAIL,
  ],
  promise: (client) => client.post('assets',
    { data: {
        asset: {
          url: url,
          project_id: projectId
        }
      }
    })
})
