import { createSelector } from 'reselect'

import { getUsersEntities } from '../entities/selectors'

export const getUsers = createSelector(
  (state) => state.users.users,
  getUsersEntities,
  (users, entities) => users.map((o) => entities[o])
)

export const makeGetUser = () => (
  createSelector(
    getUsersEntities,
    (_, userId) => userId,
    (entities, id) => entities[id]
  )
)
