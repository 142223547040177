import { createSelector } from 'reselect'

export const getOrganizationsEntities = createSelector([
  (state) => state.entities.organizations,
],
  (entities) => entities
)

export const getOrganizationOwnershipsEntities = createSelector([
  (state) => state.entities.organizationOwnerships,
],
  (entities) => entities
)

export const getProjectsEntities = createSelector([
  (state) => state.entities.projects,
],
  (entities) => entities
)

export const getProjectOwnershipsEntities = createSelector([
  (state) => state.entities.projectOwnerships,
],
  (entities) => entities
)

export const getLanguagesEntities = createSelector([
  (state) => state.entities.languages,
],
  (entities) => entities
)

export const getLocalizedStringsEntities = createSelector([
  (state) => state.entities.localizedStrings,
],
  (entities) => entities
)

export const getLocalizedStringValuesEntities = createSelector([
  (state) => state.entities.localizedStringValues,
],
  (entities) => entities
)

export const getUsersEntities = createSelector([
  (state) => state.entities.users,
],
  (entities) => entities
)
