const phrases = {
  footer: {
    project: 'Polyglot',
    by: 'By',
    gaspardbruno: 'Gaspard+Bruno',
    copyright: 'Copyright by Gaspard+Bruno Ltd, 2019. All rights reserved. All materials on this website are intellectual property of Gaspard+Bruno Ltd.',
    support: 'Support',
    business: 'Business',
  },
  sidebar: {
    logout: 'Logout',
    signout: 'Sign Out',
    collapse: 'Hide side menu',
    uncollapse: 'Show side menu',
    project: {
      translations: 'Translations',
      people: 'People',
      settings: 'Settings'
    },
  },
  login: {
    title: 'Login',
    email: {
      label: 'Email',
      placeholder: 'you@company.com'
    },
    password: {
      label: 'Password',
      placeholder: 'Your password'
    },
    submit: 'Login',
    error: {
      401: 'Invalid credentials'
    }
  },
  acceptinvitation: {
    title: 'Accept Invitation',
    firstname: {
      label: 'First Name',
      placeholder: 'First Name'
    },
    lastname: {
      label: 'Last Name',
      placeholder: 'Last Name'
    },
    password: {
      label: 'Password',
      placeholder: 'Password'
    },
    passwordconfirmation: {
      label: 'Password Confirmation',
      placeholder: 'Password Confirmation'
    },
    submit: 'Accept Invitation',
    error: {
      422: 'Invalid or expired token'
    }
  },
  dashboard: {
    projects: 'Projects',
    members: 'Team Members',
    languages: 'Languages',
    strings: 'Strings',
    projectName: 'Project Name',
    organizationName: 'Organization Name',
    addProject: 'Add New Project',
    createProject: 'Create Project',
    deleteProject: 'Delete Project',
    accessProject: 'Access Project',
    projectempty: 'You currently have no projects. Please create one below.',
    error: {
      400: 'The email is not valid',
    }
  },
  translations: {
    translationsempty: 'You currently have no strings. Please create one below.',
    localizedStringKey: 'Localized String Key',
    addLocalizedString: 'Add New String',
    update: 'Edit',
    updateLocalizedString: 'Edit Localized String Key',
    createLocalizedString: 'Create Localized String',
    error: {
      KO: 'This localized string key has already been taken'
    },
    prompt: 'You have unsaved changes, are you sure you want to leave?',
    addLanguage: 'Add Language',
    deleteLanguage: 'Remove Language',
    deleteLocalizedString: 'Delete Localized String',
    deleteLocalizedStringConfirm: 'Are you sure you want to delete this string?',
    pendingSubmit: 'No edits pending publication',
    publish: 'Publish',
    save: 'Save',
    autoSave: 'Auto-save',
    languageDropdown: {
      placeholder: 'Pick Language'
    },
    localizedStringValue: {
      source: 'source',
      updatedAt: 'Latest change on',
      placeholder: 'Type your translation here ...',
      selectLanguage: 'Select Language'
    }
  },
  projectSettings: {
    prompt: 'You have unsaved changes, are you sure you want to leave?',
    addLanguage: 'Add Language',
    deleteLanguage: 'Remove Language',
    pendingSubmit: 'No edits pending publication',
    submit: 'Submit',
    save: 'Save',
    autoSave: 'Auto-save',
    languageDropdown: {
      placeholder: 'Pick Language'
    },
    localizedStringValue: {
      source: 'source',
      updatedAt: 'Latest change on',
      placeholder: 'Type your translation here ...',
      selectLanguage: 'Select Language'
    },
    selectMode: 'Select outputMode',
    updateOutputMode: 'Update Output Mode',
    outupModeDropdown: {
      placeholder: 'Select outputMode'
    },
    updatePrefix: 'Update Prefix',
    updateInter: 'Update Inter',
    updateSuffix: 'Update Suffix'
  },
  landing: {
    opensource: 'Open Source',
    translatorssystem: 'Translation Management System',
    onesource: 'One source of truth for all the translators in your team.',
    backedby: 'Backed by Google Translate. Deploy Strings on the fly, quick and easy.',
    wemake: 'We make tools to let your products speak all languages.',
    letyour: "Let your developers create strings directly from the code using our SDK's.",
    translationdemo: {
      description: 'Poliglot bridges developers & translators to bring one source of truth for your digital products. ',
    },
    fromdevelopers: 'From Developers',
    sdk: {
      reactredux: 'React + Redux',
      ios: 'iOS (soon)',
      android: 'Android (soon)',
      ror: 'Ruby on Rails (soon)',
    },
    totranslators: 'To Translators',
    twitter: 'Twitter',
    instagram: 'Instagram',
    facebook: 'Facebook',
    copyright: 'Copyright by Gaspard+Bruno Ltd, 2020. All rights reserved. All materials on this website are intellectual property of Gaspard+Bruno Ltd.',
    support: 'Support',
    business: 'Business',
    rights: 'Texts and photos present on this site are not royalty free. All rights for representation and reproduction reserved.',
    translationdemo: {
      title: 'Translation Management System',
      try: 'Try Polyglot Out',
      hover: 'Hover the different languages and see polyglot in action',
    },
    github: 'Github',
    contact: 'Contact Us',
    login: 'Log in',
    wespeak: 'We Speak Polyglot',
  },
}

export default phrases
