import produce from 'immer'

import * as actionTypes from './actionTypes'


const initialState = {
  assets: [],
  assetsLoading: false,
  assetsError: null,
  assetLoading: false,
  assetError: null,
}

const reducer = (state = initialState, action = {}) => {
  return produce(state, draft => {
    switch (action.type) {
      // GET ASSETS
      case actionTypes.GET_ASSETS:
        draft.assetsLoading = true
        break
      case actionTypes.GET_ASSETS_SUCCESS:
        draft.assetsLoading = false
        draft.assets = action.result.data
        break
      case actionTypes.GET_ASSETS_FAIL:
        draft.assetsLoading = false
        draft.languagesError = { status: action.error.status }
        break
      // POST ASSET
      case actionTypes.POST_ASSET:
        draft.assetLoading = true
        break
      case actionTypes.POST_ASSET_SUCCESS:
        draft.assetLoading = false
        draft.assets = action.result.data
        break
      case actionTypes.POST_ASSET_FAIL:
        draft.assetLoading = false
        draft.assetError = { status: action.error.status }
        break

      default:
        break
    }
  })
}

export default reducer
