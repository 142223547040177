import React from 'react'

import useFetchProject from 'hooks/fetchProject.js'

import Contextualbar from 'components/Contextualbar'
import Loader from 'components/Loader'
import OwnershipListItem from './OwnershipListItem'

const ProjectMembers = ({ location, match }) => {
  const projectId = location.state && location.state.projectId
  const projectSlug = match.params.slug

  const project = useFetchProject(projectId, projectSlug)

  return (
    <div className="project-members-page">
      <Contextualbar
        project={project}
        activeOption="people"
      />
      { !project
        ? <Loader />
        : <div className="project-members-content">
            <div className="project-members-list">
              { project.projectOwnerships.map((ownershipId, index) => (
                  <OwnershipListItem
                    ownershipId={ownershipId}
                    key={`ownership-${index}`}
                  />
                ))
              }
            </div>
          </div>
      }
    </div>
  )
}

export default ProjectMembers
