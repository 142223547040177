import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { useTranslate } from 'polyglot-react-redux-sdk'

import { selectors as languagesSelectors } from 'store/reducers/languages'
import { selectors as localizedStringValuesSelectors } from 'store/reducers/localizedStringValues'

import { clickableElement } from 'utils/accessibility'

const Source = ({ sourceLocalizedStringValue }) => {
  const t = useTranslate('translations')

  const { language, value } = sourceLocalizedStringValue

  const getLanguage = useMemo(languagesSelectors.makeGetLanguage, [])
  const sourceLanguage = useSelector((state) => getLanguage(state, language))

  return (
    <div className="localized-string-values-source">

      <p className="localized-string-values-source-label">
        {`${sourceLanguage.name} (${t('localizedStringValue.source')})`}
      </p>
      <p className="localized-string-values-source-value">{value}</p>
    </div>
  )
}

const Value = ({ localizedStringValue, selected, dirtyValue, onUpdateValue }) => {
  const t = useTranslate('translations')

  const [value, setValue] = useState(dirtyValue || localizedStringValue.value)

  useEffect(() => {
      if (dirtyValue && dirtyValue !== value) {
          setValue(dirtyValue)
      }
  }, [dirtyValue])

  const handleUpdateValue = (value) => {
    setValue(value.target.value)
    onUpdateValue({ id: Number(localizedStringValue.id), value: value.target.value })
  }

  const className = [
    'value-field', selected === localizedStringValue.language && 'selected'
  ].filter((c) => c).join(' ')

  return (
    <div className={className}>
      <textarea
        className={"textArea"}
        value={value}
        styleType="borderless"
        type="text"
        placeholder={t('localizedStringValue.placeholder')}
        error={false}
        onChange={handleUpdateValue}
      />
    </div>
  )
}

const UpdatedAt = ({ localizedStringValue }) => {
  const t = useTranslate('translations')

  const { updatedAt } = localizedStringValue

  return (
    <div className="localized-string-values-updated-at">
      <p className="localized-string-values-updated-at-label">
        {`${t('localizedStringValue.updatedAt')} ${moment(updatedAt).format('D MMM YYYY')}`}
      </p>
    </div>
  )
}

const Language = ({ languageId, selected, onSelectLanguage }) => {
  const t = useTranslate('translations')

  const getLanguage = useMemo(languagesSelectors.makeGetLanguage, [])
  const language = useSelector((state) => getLanguage(state, languageId))

  const handleSelectLanguage = useCallback(() => {
    onSelectLanguage(language.id)
  }, [])

  const className = [
    'localized-string-values-language', selected === language.id && 'selected'
  ].filter((c) => c).join(' ')

  return (
    <div
      onClick={handleSelectLanguage}
      className={className}
      { ...clickableElement(t('localizedStringValue.selectLanguage')) }
    >
      <p className="localized-string-values-language-label">{language.name}</p>
    </div>

  )
}

const LocalizedStringValueItem = ({
  localizedStringId,
  setSelectedLanguageId,
  projectLanguages,
  projectPrimaryLanguage,
  initialInputValues,
  handleInputValues,
  onSave,
}) => {
  const t = useTranslate('translations')

  const [selectedLanguage, setSelectedLanguage] = useState(projectPrimaryLanguage ?? projectLanguages[0])

  const getLocalizedStringValues = useMemo(localizedStringValuesSelectors.makeGetLocalizedStringValues, [])
  const localizedStringValues = useSelector(state => getLocalizedStringValues(state, localizedStringId))

  const languages = useMemo(() => ([
    projectPrimaryLanguage,
    ...localizedStringValues.filter((s) => s.language !== projectPrimaryLanguage).map((s) => s.language)
  ]), [projectPrimaryLanguage, localizedStringValues])

  const sourceLocalizedStringValue = useMemo(() => {
    if (projectPrimaryLanguage) {
      return localizedStringValues.find((s) => s.language === projectPrimaryLanguage)
    } else {
      return localizedStringValues[0]
    }
  }, [projectPrimaryLanguage, localizedStringValues])

  const handleSelectLanguage = useCallback((languageId) => {
    setSelectedLanguage(languageId)
    setSelectedLanguageId(languageId)
  }, [])

  const handleSelectLanguageLeftArrow = useCallback(() => {
    const currentIndex = localizedStringValues.findIndex(v => v.language === selectedLanguage)
    const nextIndex = currentIndex === 0 ? projectLanguages.length - 1 : currentIndex - 1

    setSelectedLanguage(projectLanguages[nextIndex])
  })

  const handleSelectLanguageRightArrow = useCallback(() => {
    const currentIndex = localizedStringValues.findIndex(v => v.language === selectedLanguage)
    const nextIndex = currentIndex < projectLanguages.length - 1 ? currentIndex + 1 : 0

    setSelectedLanguage(projectLanguages[nextIndex])
  })

  const handleUpdateValues = (value) => {
    handleInputValues({ [value.id]: value })
  }

  useEffect (() => {
    setSelectedLanguageId(projectLanguages[0])
  }, [])

  return (
    <div className="localized-string-values-detail-component">
      <div className="localized-string-values-detail">
        <div className="localized-string-values-detail-content">
          <Source
            sourceLocalizedStringValue={sourceLocalizedStringValue}
          />

          { localizedStringValues.map(localizedStringValue => (
              <Value
                localizedStringValue={localizedStringValue}
                selected={selectedLanguage}
                dirtyValue={initialInputValues[localizedStringValue.id] && initialInputValues[localizedStringValue.id].value}
                onUpdateValue={handleUpdateValues}
                key={localizedStringValue.id}
              />
            ))
          }

          <div className="localized-string-values-bottom">
            <UpdatedAt
              localizedStringValue={sourceLocalizedStringValue}
            />

            <div className="language-arrows">
              <div
                onClick={handleSelectLanguageLeftArrow}
                className="arrow left"
                { ...clickableElement(t('localizedStringValue.selectLanguage')) }
              >
                {'←'}
              </div>

              <div
                onClick={handleSelectLanguageRightArrow}
                className="arrow right"
                { ...clickableElement(t('localizedStringValue.selectLanguage')) }
              >
                {'→'}
              </div>
            </div>
          </div>

        </div>

        <div className="localized-string-values-languages">
          { languages.map((value, index) => (
              <Language
                languageId={value}
                selected={selectedLanguage}
                onSelectLanguage={handleSelectLanguage}
                key={index}
              />
            ))
          }
        </div>

      </div>
    </div>
  )
}

export default LocalizedStringValueItem
