import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectors as projectOwnershipsSelectors } from 'store/reducers/projectOwnerships'

import User from 'components/User'

const OwnershipListItem = ({ ownershipId }) => {
  const getProjectOwnership = useMemo(projectOwnershipsSelectors.makeGetProjectOwnership, [])
  const projectOwnership = useSelector((state) => getProjectOwnership(state, ownershipId))

  return (
    <div className="project-detail-member">
      <User userId={projectOwnership.user} />
    </div>
  )
}

export default OwnershipListItem
