import React from 'react'
import { Link } from 'react-router-dom'

import { useTranslate } from 'polyglot-react-redux-sdk'

import { clickableElement } from 'utils/accessibility'

const ProjectMenu = ({ project, onDeleteClick }) => {
  const t = useTranslate('dashboard')

  const { id, slug } = project

  const handleDeleteClick = () => {
    onDeleteClick(slug, id)
  }

  return (
    <div className="project-menu">
      <Link to={{ pathname: `/projects/${slug}` }} className="project-menu-option link">
        <p className="project-menu-option-label">{t('accessProject')}</p>
      </Link>

      <div className="project-menu-option" onClick={handleDeleteClick} { ...clickableElement() }>
        <p className="project-menu-option-label warning">{t('deleteProject')}</p>
      </div>
    </div>
  )
}

export default ProjectMenu

