import React, { useEffect } from 'react'
import { Switch, Redirect, useLocation } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'

import AuthenticatedRoute from './AuthenticatedRoute'
import NonAuthenticatedRoute from './NonAuthenticatedRoute'
import AuthenticationDynamicRoute from './AuthenticationDynamicRoute'

import LandingPage from 'pages/Landing'
import LoginPage from 'pages/Login'
import DashboardPage from 'pages/Dashboard'
import ProjectMembersPage from 'pages/ProjectMembers'
import ProjectSettingsPage from 'pages/ProjectSettings'
import TranslationsPage from 'pages/Translations'


const Router = ({ setRoutePathName }) => {
  const location = useLocation()
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 1, height: '100%' },
    enter: { opacity: 1, height: '100%' },
    leave: { opacity: 1, height: '100%' },
  })

  function usePageViews() {
    useEffect(() => {
      setRoutePathName(location.pathname)
    }, [location])
  }

  usePageViews(setRoutePathName)

  return transitions.map(({ item, props, key }) => (
    <animated.div key={key} style={props}>
      <Switch location={location}>
        <AuthenticatedRoute path="/organizations/:slug" exact component={DashboardPage} />
        <AuthenticatedRoute path="/projects/:slug/people" exact component={ProjectMembersPage} />
        <AuthenticatedRoute path="/projects/:slug/settings" exact component={ProjectSettingsPage} />
        <AuthenticatedRoute path="/projects/:slug/translations" exact component={TranslationsPage} />

        <AuthenticationDynamicRoute path="/" exact AuthComponent={DashboardPage} NonAuthComponent={LandingPage} />

        <NonAuthenticatedRoute path="/login" exact component={LoginPage} />

        <Redirect from="/projects/:slug" to="/projects/:slug/translations" />
        <Redirect from="*" to="/" />
      </Switch>
    </animated.div>
  ))
}

export default Router
