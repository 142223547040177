import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslate } from 'polyglot-react-redux-sdk'

import { actions as localizedStringsActions, selectors as localizedStringsSelectors } from 'store/reducers/localizedStrings'

import Button from 'components/Button'
import TextField from 'components/TextField'

const CreateMenu = ({ projectId }) => {
  const t = useTranslate('translations')

  const [localizedStringKey, setLocalizedStringKey] = useState('')
  const [localizedStringKeyError, setLocalizedStringKeyError] = useState(null)

  const createLoading = useSelector(localizedStringsSelectors.getCreateLocalizedStringLoading)
  const createError = useSelector(localizedStringsSelectors.getCreateLocalizedStringError)
  const createSuccess = useSelector(state => state.localizedStrings.createLocalizedString)

  const dispatch = useDispatch()
  const createLocalizedString = useCallback((key, projectId) =>
      dispatch(localizedStringsActions.createLocalizedString(key, projectId)), [dispatch])

  const handleChangeLocalizedStringKey = useCallback((value) => {
    setLocalizedStringKey(value.target.value)
  }, [])

  const handleCreateLocalizedString = useCallback(() => {
    const projectKeyValid = localizedStringKey && localizedStringKey.length > 0

    projectKeyValid ? createLocalizedString(localizedStringKey, projectId) : setLocalizedStringKeyError(true)
  })

  useEffect(() => {
    if (createSuccess) setLocalizedStringKey('')
  }, [createSuccess])

  return (
    <div className="create-localized-string">
      <div className="create-localized-string-left-section"></div>

      <div className="create-localized-string-center-section">
        <p className="create-localized-string-title">{t('createLocalizedString')}</p>

        <div className="form">
          <div className="field">
            <TextField
              value={localizedStringKey}
              type="text"
              placeholder={t('localizedStringKey')}
              error={localizedStringKeyError}
              onChange={handleChangeLocalizedStringKey} />
          </div>

          <div className="form-error">
            { createError &&
              <p>{t(`error.${createError.status}`)}</p>
            }
          </div>

          <div className="button-container">
            <Button
              text={t('createLocalizedString')}
              action={handleCreateLocalizedString}
              type="black"
              loading={createLoading} />
          </div>
        </div>
      </div>

      <div className="create-localized-string-right-section"></div>
    </div>
  )
}

export default CreateMenu
