import { createSelector } from 'reselect'

import { getProjectOwnershipsEntities } from '../entities/selectors'

export const makeGetProjectOwnership = () => (
  createSelector(
    getProjectOwnershipsEntities,
    (_, projectOwnershipId) => projectOwnershipId,
    (entities, id) => entities[id]
  )
)
