const packageName = 'localizedStrings'

export const GET_LOCALIZED_STRINGS = `${packageName}/GET_LOCALIZED_STRINGS`
export const GET_LOCALIZED_STRINGS_SUCCESS = `${packageName}/GET_LOCALIZED_STRINGS_SUCCESS`
export const GET_LOCALIZED_STRINGS_FAIL = `${packageName}/GET_LOCALIZED_STRINGS_FAIL`

export const CREATE_LOCALIZED_STRING = `${packageName}/CREATE_LOCALIZED_STRING`
export const CREATE_LOCALIZED_STRING_SUCCESS = `${packageName}/CREATE_LOCALIZED_STRING_SUCCESS`
export const CREATE_LOCALIZED_STRING_FAIL = `${packageName}/CREATE_LOCALIZED_STRING_FAIL`

export const UPDATE_LOCALIZED_STRING = `${packageName}/UPDATE_LOCALIZED_STRING`
export const UPDATE_LOCALIZED_STRING_SUCCESS = `${packageName}/UPDATE_LOCALIZED_STRING_SUCCESS`
export const UPDATE_LOCALIZED_STRING_FAIL = `${packageName}/UPDATE_LOCALIZED_STRING_FAIL`

export const DELETE_LOCALIZED_STRING = `${packageName}/DELETE_LOCALIZED_STRING`
export const DELETE_LOCALIZED_STRING_SUCCESS = `${packageName}/DELETE_LOCALIZED_STRING_SUCCESS`
export const DELETE_LOCALIZED_STRING_FAIL = `${packageName}/DELETE_LOCALIZED_STRING_FAIL`
