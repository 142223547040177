import React from 'react'
import { useSelector } from 'react-redux'

import { selectors as authSelectors } from 'store/reducers/auth'

const AuthenticationDynamicRoute = ({ AuthComponent, NonAuthComponent, ...routeProps }) => {
  const authenticated = useSelector(authSelectors.isAuthenticated)

  return (
    authenticated ?
      <AuthComponent {...routeProps} />
      :
      <NonAuthComponent {...routeProps} />
  )
}

export default AuthenticationDynamicRoute
