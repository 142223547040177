import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectors as usersSelectors } from 'store/reducers/users'

const User = ({ userId }) => {
  const getUser = useMemo(usersSelectors.makeGetUser, [])
  const user = useSelector((state) => getUser(state, userId))

  if (!user) return null

  const { avatar, email, fullName } = user

  return (
    <div className="user-component">
      { avatar
        ? <img className="user-image "src={user.avatar} alt={fullName} />
        : <p className="user-initials">{user.email.slice(0,2)}</p>
      }

      <div className="user-details">
        <p className="user-name">{fullName}</p>
        <p className="user-email">{email}</p>
      </div>
    </div>
  )
}

export default User
