import { createSelector } from 'reselect'

import { getProjectsEntities } from '../entities/selectors'

export const getProjects = createSelector(
  (state) => state.projects.projects,
  getProjectsEntities,
  (projects, entities) => projects.map((o) => entities[o])
)

export const makeGetProject = () => (
  createSelector(
    getProjectsEntities,
    (_, projectId) => projectId,
    (entities, id) => entities[id]
  )
)

export const getCreateProjectLoading = createSelector([
  (state) => state.projects.createProjectLoading
],
(loading) => loading
)

export const getCreateProjectError = createSelector([
  (state) => state.projects.createProjectError
],
(error) => error
)

export const getCreateProjectLanguageLoading = createSelector([
  (state) => state.projects.createProjectLanguage
],
(loading) => loading
)

export const getDeleteProjectLanguageLoading = createSelector([
  (state) => state.projects.deleteProjectLanguage
],
(loading) => loading
)

export const getPresignedUrl = createSelector([
  (state) => state.projects.presignedUrl
],
(presignedUrl) => presignedUrl
)
