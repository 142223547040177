import * as actionTypes from './actionTypes'

export const getLanguages = () => ({
  types: [
    actionTypes.GET_LANGUAGES,
    actionTypes.GET_LANGUAGES_SUCCESS,
    actionTypes.GET_LANGUAGES_FAIL,
  ],
  promise: (client) => client.get('languages')
})
