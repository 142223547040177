import { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  actions as localizedStringsActions,
  selectors as localizedStringsSelectors
} from 'store/reducers/localizedStrings'
import {
  actions as projectsActions,
  selectors as projectsSelectors
} from 'store/reducers/projects'

const useFetchTranslations = (projectFetched, projectSlug) => {
  const [projectId, setProjectId] = useState(projectFetched || null)

  const dispatch = useDispatch()
  const getProject = useCallback(projectSlug =>
      dispatch(projectsActions.getProject(projectSlug), [dispatch]))
  const getLocalizedStrings = useCallback(projectId =>
      dispatch(localizedStringsActions.getLocalizedStrings(projectId), [dispatch]))

  const makeProject = useMemo(projectsSelectors.makeGetProject, [])
  const project = useSelector((state) => makeProject(state, projectId))
  
  const localizedStrings = useSelector((state) => localizedStringsSelectors.getLocalizedStrings(state))

  useEffect(() => {
    getProject(projectSlug).then((project) => {
      setProjectId(project.data.id)
      getLocalizedStrings(project.data.id)
    })
  }, [])

  return { project, localizedStrings }
}

export default useFetchTranslations
