import { createSelector } from 'reselect'

import { getLocalizedStringsEntities, getLocalizedStringValuesEntities } from '../entities/selectors'

export const makeGetLocalizedStringValues = () => (
  createSelector(
    getLocalizedStringsEntities,
    getLocalizedStringValuesEntities,
    (_, localizedStringId) => localizedStringId,
    (localizedStringEntities, localizedStringValueEntities, id) => {
      if (localizedStringEntities && localizedStringValueEntities && id) {
        return (
          localizedStringEntities[id].localizedStringValues
            .map((o) => localizedStringValueEntities[o])
            .concat()
            .sort((a, b) => a.language - b.language)
        )
      }
    }
  )
)

export const makeGetLocalizedStringValue = () => (
  createSelector(
    getLocalizedStringValuesEntities,
    (_, localizedStringValueId) => localizedStringValueId,
    (entities, id) => entities[id]
  )
)

export const getUpdateLocalizedStringValueLoading = createSelector([
  (state) => state.localizedStringValues.updateLocalizedStringValue
],
  (loading) => loading
)

export const getTranslatedValue = createSelector([
  (state) => state.localizedStringValues.translatedValue
],
  (translatedValue) => translatedValue
)
