import React, { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectors as languagesSelectors } from 'store/reducers/languages'
import { selectors as localizedStringValuesSelectors } from 'store/reducers/localizedStringValues'
import { selectors as localizedStringsSelectors } from 'store/reducers/localizedStrings'

import { clickableElement } from 'utils/accessibility'

const LocalizedStringValue = ({ localizedStringValueId }) => {
  const getLocalizedStringValue = useMemo(localizedStringValuesSelectors.makeGetLocalizedStringValue, [])
  const localizedStringValue = useSelector((state) => getLocalizedStringValue(state, localizedStringValueId))

  const { value } = localizedStringValue

  return (
    <p className="localized-string-value">{value}</p>
  )
}

const Language = ({ languageId, translatedLanguages, selectedLanguage }) => {
  const getLanguage = useMemo(languagesSelectors.makeGetLanguage, [])
  const language = useSelector((state) => getLanguage(state, languageId))

  const hasTranslation = translatedLanguages.filter(lang => lang === languageId).length > 0

  const className = ['localized-string-language', hasTranslation && 'translated'
      ].filter(c => c).join(' ')

  return (
    <div className={className}>
      <p className="localized-string-language-label">{language.code.slice(-2)}</p>
    </div>
  )
}

const LocalizedStringListItem = ({ localizedString, languages, selected, setSelectedLanguageId, onClick }) => {
  const getTranslatedLanguages = useMemo(localizedStringsSelectors.makeGetTranslatedLanguages, [])
  const translatedLanguages = useSelector((state) => getTranslatedLanguages(state, localizedString.id))

  const { key, localizedStringValues } = localizedString

  const className = ['localized-string-detail', selected && 'selected'].filter(c => c).join(' ')

  const handleClick = useCallback(() => {
    onClick(localizedString)
  }, [])

  return (
    <div className={className} onClick={handleClick} { ...clickableElement(key) }>
      <div className="left">
        { localizedStringValues.length > 0 &&
          <LocalizedStringValue localizedStringValueId={localizedStringValues.concat().sort((a, b) => a - b)[0]} />
        }

        <p className="localized-string-key">{key}</p>
      </div>

      <div className="localized-string-languages">
        { languages.map((langId, index) => (
            <Language
              languageId={langId}
              setSelectedLanguageId={setSelectedLanguageId}
              translatedLanguages={translatedLanguages}
              key={index}
            />
          ))
        }
      </div>
    </div>
  )
}

export default LocalizedStringListItem
