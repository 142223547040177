const packageName = 'projects'

export const GET_PROJECTS = `${packageName}/GET_PROJECTS`
export const GET_PROJECTS_SUCCESS = `${packageName}/GET_PROJECTS_SUCCESS`
export const GET_PROJECTS_FAIL = `${packageName}/GET_PROJECTS_FAIL`

export const GET_PROJECT = `${packageName}/GET_PROJECT`
export const GET_PROJECT_SUCCESS = `${packageName}/GET_PROJECT_SUCCESS`
export const GET_PROJECT_FAIL = `${packageName}/GET_PROJECT_FAIL`

export const CREATE_PROJECT = `${packageName}/CREATE_PROJECT`
export const CREATE_PROJECT_SUCCESS = `${packageName}/CREATE_PROJECT_SUCCESS`
export const CREATE_PROJECT_FAIL = `${packageName}/CREATE_PROJECT_FAIL`

export const DELETE_PROJECT = `${packageName}/DELETE_PROJECT`
export const DELETE_PROJECT_SUCCESS = `${packageName}/DELETE_PROJECT_SUCCESS`
export const DELETE_PROJECT_FAIL = `${packageName}/DELETE_PROJECT_FAIL`

export const CREATE_PROJECT_LANGUAGE = `${packageName}/CREATE_PROJECT_LANGUAGE`
export const CREATE_PROJECT_LANGUAGE_SUCCESS = `${packageName}/CREATE_PROJECT_LANGUAGE_SUCCESS`
export const CREATE_PROJECT_LANGUAGE_FAIL = `${packageName}/CREATE_PROJECT_LANGUAGE_FAIL`

export const DELETE_PROJECT_LANGUAGE = `${packageName}/DELETE_PROJECT_LANGUAGE`
export const DELETE_PROJECT_LANGUAGE_SUCCESS = `${packageName}/DELETE_PROJECT_LANGUAGE_SUCCESS`
export const DELETE_PROJECT_LANGUAGE_FAIL = `${packageName}/DELETE_PROJECT_LANGUAGE_FAIL`

export const UPDATE_OUTPUT_MODE = `${packageName}/UPDATE_OUTPUT_MODE`
export const UPDATE_OUTPUT_MODE_SUCCESS = `${packageName}/UPDATE_OUTPUT_MODE_SUCCESS`
export const UPDATE_OUTPUT_MODE_FAIL = `${packageName}/UPDATE_OUTPUT_MODE_FAIL`

export const GET_PRESIGNED_URL = `${packageName}/GET_PRESIGNED_URL`
export const GET_PRESIGNED_URL_SUCCESS = `${packageName}/GET_PRESIGNED_URL_SUCCESS`
export const GET_PRESIGNED_URL_FAIL = `${packageName}/GET_PRESIGNED_URL_FAIL`

export const PUBLISH_STRINGS = `${packageName}/PUBLISH_STRINGS`
export const PUBLISH_STRINGS_SUCCESS = `${packageName}/PUBLISH_STRINGS_SUCCESS`
export const PUBLISH_STRINGS_FAIL = `${packageName}/PUBLISH_STRINGS_FAIL`
