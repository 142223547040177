import produce from 'immer'

import * as actionTypes from './actionTypes'
import {
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_SUCCESS,
  CREATE_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  CREATE_PROJECT_LANGUAGE_SUCCESS,
  DELETE_PROJECT_LANGUAGE_SUCCESS,
} from '../projects/actionTypes'
import {
  GET_LANGUAGES_SUCCESS,
} from '../languages/actionTypes'
import {
  GET_LOCALIZED_STRINGS_SUCCESS,
  CREATE_LOCALIZED_STRING_SUCCESS,
  UPDATE_LOCALIZED_STRING_SUCCESS,
} from '../localizedStrings/actionTypes'
import {
  BULK_UPDATE_LOCALIZED_STRING_VALUE_SUCCESS,
} from '../localizedStringValues/actionTypes'


const initialState = {
  projects: {},
  projectOwnerships: {},
  languages: {},
  localizedStrings: {},
  localizedStringValues: {},
  users: {}
}

const normalizeArray = (arr) => {
  const normalized = {}
  Object.keys(arr).forEach((key) => {
    const { id, attributes, relationships } = arr[key]

    const relationshipsAttributes = {}
    if (relationships) {
      Object.keys(relationships).forEach((key) => {
        const relationship = relationships[key].data
        if (relationship) {
          relationshipsAttributes[key] = relationship instanceof Array ? relationship.map((a) => a.id) : relationship.id
        }
      })
    }

    normalized[id] = { id, ...attributes, ...relationshipsAttributes }
  })

  return normalized
}

const updateEntitiesData = (state, draft, entity, dataKey, data) => {
  const entityData = data[dataKey]

  if (entityData) {
    draft[entity] = {
      ...state[entity],
      ...normalizeArray(entityData)
    }
  }
}

const reducer = (state = initialState, action = {}) => {
  return produce(state, draft => {
    switch (action.type) {
      // UPDATE ENTITIES DATA
      case GET_PROJECTS_SUCCESS:
      case GET_PROJECT_SUCCESS:
      case CREATE_PROJECT_SUCCESS:
      case CREATE_PROJECT_LANGUAGE_SUCCESS:
      case DELETE_PROJECT_LANGUAGE_SUCCESS:
      case GET_LANGUAGES_SUCCESS:
      case GET_LOCALIZED_STRINGS_SUCCESS:
      case CREATE_LOCALIZED_STRING_SUCCESS:
      case UPDATE_LOCALIZED_STRING_SUCCESS:
      case BULK_UPDATE_LOCALIZED_STRING_VALUE_SUCCESS:
        const data = action.result.normalizedData
        updateEntitiesData(state, draft, 'projects', 'project', data)
        updateEntitiesData(state, draft, 'projectOwnerships', 'projectOwnership', data)
        updateEntitiesData(state, draft, 'languages', 'language', data)
        updateEntitiesData(state, draft, 'users', 'user', data)
        updateEntitiesData(state, draft, 'localizedStrings', 'localizedString', data)
        updateEntitiesData(state, draft, 'localizedStringValues', 'localizedStringValue', data)
        break

      // DELETE ENTITIES DATA
      case DELETE_PROJECT_SUCCESS:
        delete draft.projects[action.params.projectId]
        break

      case actionTypes.RESET:
        draft.entities = initialState.entities
        break

      default:
        break;
    }
  })
}

export default reducer
