import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useTranslate } from 'polyglot-react-redux-sdk'

import { actions as settingsActions, selectors as settingsSelectors } from 'store/reducers/settings'

import { clickableElement } from 'utils/accessibility'

import ProjectMenu from './ProjectMenu'

const Contextualbar = ({ project, activeOption }) => {
  const t = useTranslate('sidebar')

  const collapsed = useSelector((state) => settingsSelectors.getMenu(state, 'project'))

  const dispatch = useDispatch()
  const toggleMenu = useCallback(() => dispatch(settingsActions.toggleMenu('project')), [dispatch])

  const handleCollapse = () => {
    toggleMenu()
  }

  const className = ['contextualbar-component', collapsed && 'collapsed'].filter((c) => c).join(' ')

  return (
    <div className={className}>
      { project &&
        <ProjectMenu
          project={project}
          activeOption={activeOption}
          collapsed={collapsed}
        />
      }

      <div
        onClick={handleCollapse}
        className="contextualbar-button-collapse" { ...clickableElement(t(`${!collapsed ? '' : 'un'}collapse`)) }
      >
        {!collapsed ?
          <div className="contextualbar-button-collapse-icon">
            {'←'}
          </div>
        :
          <div className="contextualbar-button-collapse-icon">
            {'→'}
          </div>
        }
      </div>
    </div>
  )
}

export default Contextualbar
