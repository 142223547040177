import React, { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useTranslate } from 'polyglot-react-redux-sdk'

import { selectors as languagesSelectors } from 'store/reducers/languages'

import { clickableElement } from 'utils/accessibility'

const Language = ({ languageId }) => {
  const getLanguage = useMemo(languagesSelectors.makeGetLanguage, [])
  const language = useSelector((state) => getLanguage(state, languageId))

  return (
    <div className="project-list-item-language">
      <p className="project-list-item-language-label">{language.code.slice(-2)}</p>
    </div>
  )
}

const ProjectListItem = ({ project, selected, onClick }) => {
  const t = useTranslate('dashboard')

  const { languages, localizedStringsCount, name, projectOwnerships } = project

  const handleClick = useCallback(() => {
    onClick(project)
  }, [])

  const className = ['project-list-item', selected && 'selected'].filter(c => c).join(' ')

  return (
    <div className={className} onClick={handleClick} { ...clickableElement(name) }>
      <div className="project-list-item-top-section">
        <p className="project-list-item-name">{name}</p>
        <p className="project-list-item-projects">
          {`${t('members')} (${projectOwnerships.length})`}
        </p>
      </div>

      <div className="project-list-item-bottom-section">
        <div className="project-list-item-strings">
          <p className="project-list-item-strings-label">{t('strings')}</p>
          <p className="project-list-item-strings-count">{localizedStringsCount || 0}</p>
        </div>

        <div className="project-list-item-languages">
          <p>{t('languages')}</p>

          <div className="project-list-item-languages-labels">
            { languages.map((langId, index) => (
                <Language
                  languageId={langId}
                  key={index}
                />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectListItem
