import * as actionTypes from './actionTypes'

export const toggleMenu = (menu) => (
  (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_MENU,
      payload: { menu },
    })
  }
)
