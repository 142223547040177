import React, { useState, useEffect, useMemo, useReducer, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt } from 'react-router'
import { toast } from 'react-toastify'

import { useTranslate } from 'polyglot-react-redux-sdk'
import useFetchTranslations from 'hooks/fetchTranslations.js'

import { selectors as languagesSelectors } from 'store/reducers/languages'
import { actions as projectsActions } from 'store/reducers/projects'
import {
  actions as localizedStringValuesActions,
  selectors as localizedStringValuesSelectors
} from 'store/reducers/localizedStringValues'

import { clickableElement } from 'utils/accessibility'

import CreateMenu from './CreateMenu'
import Contextualbar from 'components/Contextualbar'
import Loader from 'components/Loader'
import LocalizedStringListItem from './LocalizedStringListItem'
import LocalizedStringValueItem from './LocalizedStringValueItem'
import Switch from 'components/Switch'
import UpdateMenu from './UpdateMenu'

import googleTranslate from 'assets/images/google_translate.png'

const Translations = ({ location, match }) => {
  const t = useTranslate('translations')

  const projectId = location.state && location.state.projectId
  const projectSlug = match.params.slug

  const [selectedLocalizedString, setSelectedLocalizedString] = useState(null)
  const [openCreateLocalizedString, setOpenCreateLocalizedString] = useState(false)
  const [openUpdateLocalizedString, setOpenUpdateLocalizedString] = useState(false)
  const [autoSave, setAutoSave] = useState(false)

  const [localizedStringValues, setLocalizedStringValues] = useReducer(reducer, {})

  const updateLocalizedStringValuesSuccess = useSelector(state =>
      localizedStringValuesSelectors.getUpdateLocalizedStringValueLoading(state))

  const project = useFetchTranslations(projectId, projectSlug).project
  const projectPrimaryLanguage = project?.primaryLanguage ? `${project.primaryLanguage}` : null
  const localizedStrings = useFetchTranslations(projectId, projectSlug).localizedStrings
  const dispatch = useDispatch()
  const createProjectLanguage = useCallback((projectId, languageId) =>
      dispatch(projectsActions.createProjectLanguage(projectId, languageId)), [dispatch])
  const deleteProjectLanguage = useCallback((projectId, languageId) =>
      dispatch(projectsActions.deleteProjectLanguage(projectId, languageId)), [dispatch])
  const bulkUpdateLocalizedStringValue = useCallback((values, projectId) =>
      dispatch(localizedStringValuesActions.bulkUpdateLocalizedStringValue(values, projectId)), [dispatch])

  const localizedStringsCount = localizedStrings.length
  const localizedStringValuesIsDirty = Object.getOwnPropertyNames(localizedStringValues).length !== 0
  const shouldShowLocalizedStringValueActions = !openCreateLocalizedString && !openUpdateLocalizedString
      && selectedLocalizedString

  const [debouncedAutoSave, setDebouncedAutoSave] = useState(null)

  function reducer(state, action) {
    switch (action.type) {
      case 'add':
        return { ...state, ...action.payload }
      case 'reset':
        return {}
      default:
        throw new Error()
    }
  }

  const handleOpenCreateLocalizedString = useCallback(() => {
    setOpenUpdateLocalizedString(false)
    setOpenCreateLocalizedString(true)
    setSelectedLocalizedString(null)
  }, [])

  const handleOpenUpdateLocalizedString = useCallback(() => {
    setOpenCreateLocalizedString(false)
    setOpenUpdateLocalizedString(!openUpdateLocalizedString)
    if (!selectedLocalizedString) setSelectedLocalizedString(localizedStrings[0])
  }, [openUpdateLocalizedString, selectedLocalizedString, localizedStrings])

  const handleOpenSelectedLocalizedString = useCallback((localizedString) => {
    setOpenCreateLocalizedString(false)
    setSelectedLocalizedString(localizedString)
  }, [])

  const handleCreateProjectLanguage = useCallback((languageId) => {
    createProjectLanguage(projectSlug, languageId)
  }, [createProjectLanguage, projectSlug])

  const handleDeleteProjectLanguage = useCallback((languageId) => {
    deleteProjectLanguage(projectSlug, languageId)
  }, [deleteProjectLanguage, projectSlug])

  const handleUpdateLocalizedStringValues = useCallback(() => {
    const values = Object.values(localizedStringValues)
    bulkUpdateLocalizedStringValue(values, project.id).then(() => {
      toast.dark('Your strings have been saved!')
    })
  }, [bulkUpdateLocalizedStringValue, localizedStringValues, project])

  const handleSubmitLocalizedStringValues = useCallback(() => {
    dispatch(projectsActions.publishStrings(projectSlug)).then(() => {
      toast.dark('Your strings have been published!')
    })
  }, [dispatch, projectSlug])

  const [selectedLanguage, setSelectedLanguage] = useState(null)

  const getLanguage = useMemo(languagesSelectors.makeGetLanguage, [])
  const selectedLanguageObject = useSelector((state) => getLanguage(state, parseInt(selectedLanguage)))

  const getLocalizedStringValues = useMemo(localizedStringValuesSelectors.makeGetLocalizedStringValues, [])
  const localizedStringValuesData = useSelector(state => getLocalizedStringValues(state, selectedLocalizedString && selectedLocalizedString.id))

  const sourceLocalizedStringValue = localizedStringValuesData && localizedStringValuesData.length > 0 && localizedStringValuesData[0]
  const sourceLanguageObject = useSelector((state) => getLanguage(state, sourceLocalizedStringValue && parseInt(sourceLocalizedStringValue.language)))

  const translatedValue = useSelector(state => localizedStringValuesSelectors.getTranslatedValue(state))

  useEffect(() => {
    localizedStringValuesData && localizedStringValuesData.forEach(localizedStringVal => {
      if (localizedStringVal.language === selectedLanguageObject.id) {

          let inputValue = {}
          inputValue[localizedStringVal.id] = {}
          inputValue[localizedStringVal.id]["id"] = parseInt(localizedStringVal.id)
          inputValue[localizedStringVal.id]["value"] = translatedValue

          handleInputValues(inputValue)
      }
    })

  }, [translatedValue])

  const handleGoogleTranslate = () => {

    // English code is 27



    const sourceValue = sourceLocalizedStringValue
    console.log("localizedStringValuesData", localizedStringValuesData)
    console.log("sourceValue",localizedStringValuesData.find( ({ language }) => language === '27' ))

    requestGoogleTranslate("en-uk", selectedLanguageObject.code, localizedStringValuesData.find( ({ language }) => language === '27' ).value)
  }

  const requestGoogleTranslate = useCallback((sourceLanguage, destinationLanguage, value) =>
      dispatch(localizedStringValuesActions.googleTranslate(sourceLanguage, destinationLanguage, value)), [dispatch])

  const handleInputValues = (value) => {
    setLocalizedStringValues({ type: 'add', payload: value })
  }

  const handleAutoSave = (autoSave) => {
    setAutoSave(autoSave)
  }

  useEffect(() => {
    setSelectedLocalizedString(localizedStrings[0])
    setOpenUpdateLocalizedString(false)
    setOpenCreateLocalizedString(false)
  }, [localizedStringsCount])

  useEffect(() => {
    if (updateLocalizedStringValuesSuccess) {
      setLocalizedStringValues({ type: 'reset' })

      if (debouncedAutoSave) setDebouncedAutoSave(clearTimeout(debouncedAutoSave))
    }
  }, [updateLocalizedStringValuesSuccess])

  useEffect(() => {
    if (autoSave && localizedStringValuesIsDirty & !debouncedAutoSave) {
      setDebouncedAutoSave(setTimeout(() => {
        handleUpdateLocalizedStringValues()
      }, 5000))
    }
  }, [autoSave, localizedStringValues])

  useEffect(() => {
    return () => {
      if (debouncedAutoSave) setDebouncedAutoSave(clearTimeout(debouncedAutoSave))
    }
  }, [])

  const submitActionClassName = [
    'localized-string-value-submit-action',
    shouldShowLocalizedStringValueActions && 'show',
    !localizedStringValuesIsDirty && 'disabled'
  ].filter((c) => c).join(' ')

  const saveActionsClassName = [
    'localized-string-value-save-actions',
    shouldShowLocalizedStringValueActions && 'show',
    !localizedStringValuesIsDirty && 'disabled'
  ].filter((c) => c).join(' ')

  const showLocalizedStringValueForm = useMemo(() => (
    !openCreateLocalizedString &&
    !openUpdateLocalizedString &&
    selectedLocalizedString &&
    project &&
    project.languages.length > 0
  ), [openCreateLocalizedString, openUpdateLocalizedString, selectedLocalizedString, project])


  const renderRightSection = () => {
    if (openCreateLocalizedString) {
      return <CreateMenu projectId={project && project.id} />
    }

    if (openUpdateLocalizedString) {
      return <UpdateMenu
        initialLocalizedStringKey={selectedLocalizedString && selectedLocalizedString.key}
        localizedStringId={selectedLocalizedString && selectedLocalizedString.id}
        projectLanguages={project && project.languages.concat().sort((a, b) => a - b)}
        onCreateLanguage={handleCreateProjectLanguage}
        onDeleteLanguage={handleDeleteProjectLanguage} />
    }

    if (showLocalizedStringValueForm) {
      return (
        <>
          <div className={submitActionClassName}>
             { false && <p className="submit-label">{!localizedStringValuesIsDirty && t('pendingSubmit')}</p> }

            <div
              onClick={handleSubmitLocalizedStringValues}
              className="button-submit-localized-string-values"
              { ...clickableElement(t('addLocalizedString')) }>
              <p className="button-text">{t('publish')}</p>
            </div>
          </div>

          <LocalizedStringValueItem
            localizedStringId={selectedLocalizedString && selectedLocalizedString.id}
            projectLanguages={project.languages.concat().sort((a, b) => a - b)}
            projectPrimaryLanguage={projectPrimaryLanguage}
            initialInputValues={localizedStringValues}
            handleInputValues={handleInputValues}
            onSave={handleUpdateLocalizedStringValues}
            setSelectedLanguageId={setSelectedLanguage} />

          <div className={saveActionsClassName}>
            <div
              onClick={ handleGoogleTranslate }
              className="button-save-localized-string-values-gt">
              <img className="google-translate-img" alt="google-translate-img"src={googleTranslate} />
            </div>

            <div className="action-left">
              <Switch
                checked={autoSave}
                label={'Auto-save'}
                onChange={handleAutoSave}
              />

              <div
                onClick={handleUpdateLocalizedStringValues}
                className="button-save-localized-string-values"
                { ...clickableElement(t('addLocalizedString')) }
              >
                <p className="button-text">{t('save')}</p>
              </div>
            </div>
          </div>
        </>
      );
    }

    return null
  }

  return (
    <React.Fragment>
      <Prompt
        when={localizedStringValuesIsDirty}
        message={t('prompt')}
      />

      <div className="project-translations-page">
        <Contextualbar
          project={project}
          activeOption="translations"
        />

        { !project
          ? <Loader />
          : <div className="project-translations-content">
              <div className="left-section">
              { localizedStringsCount ?
                  <div className="translations-list">
                    { localizedStrings.map(localizedString => (
                        <LocalizedStringListItem
                          localizedString={localizedString}
                          languages={project.languages.concat().sort((a, b) => a - b)}
                          selected={selectedLocalizedString && selectedLocalizedString.id === localizedString.id}
                          onClick={handleOpenSelectedLocalizedString}
                          key={localizedString.id}
                        />
                      ))
                    }
                  </div>
                  :
                  <div className="translations-empty">
                    <p className="translations-empty-title">{t('translationsempty')}</p>
                  </div>
                }

                <div className="localized-string-actions">
                  <div
                    onClick={handleOpenUpdateLocalizedString}
                    className="button-edit-localized-string"
                    { ...clickableElement(t('update')) }>
                    <p className="button-text">{t('update')}</p>
                  </div>

                  <div
                    onClick={handleOpenCreateLocalizedString}
                    className="button-add-localized-string"
                    { ...clickableElement(t('addLocalizedString')) }>
                    <p className="button-text">{t('addLocalizedString')}</p>
                  </div>
                </div>
              </div>

              <div className="right-section">
                { renderRightSection() }
              </div>
            </div>
        }
      </div>
    </React.Fragment>
  )
}

export default Translations
