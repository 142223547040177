import React, { useCallback } from 'react'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useQueryParams } from 'hooks/location'

import { selectors as authSelectors } from 'store/reducers/auth'

import LoginPage from 'pages/Login'
import AcceptInvitationPage from 'pages/AcceptInvitation'

const AuthenticatedRoute = (routeProps) => {
  const authenticated = useSelector(authSelectors.isAuthenticated)

  const query = useQueryParams()

  const renderNonAuthenticatedPage = useCallback(() => {
    const token = query.get('invitation_token')

    return token ? <AcceptInvitationPage token={token} /> : <LoginPage />
  }, [query])

  return (
    authenticated ?
      <Route {...routeProps} />
      :
      renderNonAuthenticatedPage()
  )
}

export default AuthenticatedRoute
