const keyPress = (event) => {
  if (event.key === 'Enter') {
    event.target.click()
  }
}

const clickableElement = (name) => ({
  role: 'button',
  tabIndex: 0,
  'aria-label': name,
  onKeyPress: keyPress
})

export { clickableElement }
