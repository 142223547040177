import * as actionTypes from './actionTypes'

export const getProjects = () => ({
  types: [
    actionTypes.GET_PROJECTS,
    actionTypes.GET_PROJECTS_SUCCESS,
    actionTypes.GET_PROJECTS_FAIL,
  ],
  promise: (client) => client.get('projects')
})

export const getProject = (projectSlug) => ({
  types: [
    actionTypes.GET_PROJECT,
    actionTypes.GET_PROJECT_SUCCESS,
    actionTypes.GET_PROJECT_FAIL,
  ],
  promise: (client) => client.get(`projects/${projectSlug}`)
})

export const createProject = (name, organizationId) => ({
  types: [
    actionTypes.CREATE_PROJECT,
    actionTypes.CREATE_PROJECT_SUCCESS,
    actionTypes.CREATE_PROJECT_FAIL,
  ],
  promise: (client) => client.post(
    'projects',
    { data: { project: { name, organization_id: organizationId } } })
})

export const deleteProject = (projectSlug, projectId) => ({
  types: [
    actionTypes.DELETE_PROJECT,
    actionTypes.DELETE_PROJECT_SUCCESS,
    actionTypes.DELETE_PROJECT_FAIL,
  ],
  params: { projectId },
  promise: (client) => client.delete(`projects/${projectSlug}`)
})

export const createProjectLanguage = (projectSlug, languageId) => ({
  types: [
    actionTypes.CREATE_PROJECT_LANGUAGE,
    actionTypes.CREATE_PROJECT_LANGUAGE_SUCCESS,
    actionTypes.CREATE_PROJECT_LANGUAGE_FAIL,
  ],
  promise: (client) => client.post(
    `projects/${projectSlug}/add_language`,
    { data: { project: { language_id: languageId } } })
})

export const deleteProjectLanguage = (projectSlug, languageId) => ({
  types: [
    actionTypes.DELETE_PROJECT_LANGUAGE,
    actionTypes.DELETE_PROJECT_LANGUAGE_SUCCESS,
    actionTypes.DELETE_PROJECT_LANGUAGE_FAIL,
  ],
  promise: (client) => client.post(
    `projects/${projectSlug}/remove_language`,
    { data: { project: { language_id: languageId } } })
})

export const updateOutputMode = (projectSlug, outputMode,  prefix, inter, suffix) => ({
  types: [
    actionTypes.UPDATE_OUTPUT_MODE,
    actionTypes.UPDATE_OUTPUT_MODE_SUCCESS,
    actionTypes.UPDATE_OUTPUT_MODE_FAIL,
  ],
  promise: (client) => client.post(
    `projects/${projectSlug}/output_mode`,
    { data: { project: {
          outputMode: outputMode,
          prefix: prefix,
          inter: inter,
          suffix: suffix
        }
      }
    })
})

export const getPresignUrl = (projectSlug, filename) => ({
  types: [
    actionTypes.GET_PRESIGNED_URL,
    actionTypes.GET_PRESIGNED_URL_SUCCESS,
    actionTypes.GET_PRESIGNED_URL_FAIL,
  ],
  promise: (client) => client.post(
    `projects/${projectSlug}/presigned_url`,
    { data: { asset: {
          filename: filename,
        }
      }
    })
})

export const publishStrings = (projectSlug) => ({
  types: [
    actionTypes.PUBLISH_STRINGS,
    actionTypes.PUBLISH_STRINGS_SUCCESS,
    actionTypes.PUBLISH_STRINGS_FAIL,
  ],
  promise: (client) => client.post(
    `projects/${projectSlug}/publish_strings`,
    { }
  )
})
