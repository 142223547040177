import { combineReducers } from 'redux'
import { reducer as polyglotReducer } from 'polyglot-react-redux-sdk'

import entities from './entities'
import auth from './auth'
import languages from './languages'
import localizedStrings from './localizedStrings'
import localizedStringValues from './localizedStringValues'
import projects from './projects'
import settings from './settings'
import assets from './assets'

export default combineReducers({
  entities,
  polyglot: polyglotReducer,
  auth,
  languages,
  localizedStrings,
  localizedStringValues,
  projects,
  settings,
  assets,
})
