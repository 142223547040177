import produce from 'immer'

import * as actionTypes from './actionTypes'


const initialState = {
  languages: [],
  languagesLoading: false,
  languagesError: null,
}

const reducer = (state = initialState, action = {}) => {
  return produce(state, draft => {
    switch (action.type) {
      // GET LOCALIZED_STRINGS
      case actionTypes.GET_LANGUAGES:
        draft.languagesLoading = true
        break
      case actionTypes.GET_LANGUAGES_SUCCESS:
        draft.languagesLoading = false
        draft.languages = action.result.data.map((d) => d.id)
        break
      case actionTypes.GET_LANGUAGES_FAIL:
        draft.languagesLoading = false
        draft.languagesError = { status: action.error.status }
        break

      default:
        break
    }
  })
}

export default reducer
