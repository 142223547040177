import * as actionTypes from './actionTypes'

export const updateLocalizedStringValue = (value, localizedStringValueId) => ({
  types: [
    actionTypes.UPDATE_LOCALIZED_STRING_VALUE,
    actionTypes.UPDATE_LOCALIZED_STRING_VALUE_SUCCESS,
    actionTypes.UPDATE_LOCALIZED_STRING_VALUE_FAIL,
  ],
  promise: (client) => client.put(
    `localized_string_values/${localizedStringValueId}`,
    { data: { localized_string_value: { value } } })
})

export const bulkUpdateLocalizedStringValue = (values, projectId) => ({
  types: [
    actionTypes.BULK_UPDATE_LOCALIZED_STRING_VALUE,
    actionTypes.BULK_UPDATE_LOCALIZED_STRING_VALUE_SUCCESS,
    actionTypes.BULK_UPDATE_LOCALIZED_STRING_VALUE_FAIL,
  ],
  promise: (client) => client.patch(
    'localized_string_values/bulk_update',
    { data: { localized_string_values: { values, project_id: projectId } } })
})

export const googleTranslate = (sourceLanguage, destinationLanguage, value) => ({
  types: [
    actionTypes.GOOGLE_TRANSLATE,
    actionTypes.GOOGLE_TRANSLATE_SUCCESS,
    actionTypes.GOOGLE_TRANSLATE_FAIL,
  ],
  promise: (client) => client.post('localized_string_values/translate',
    { data: {
        translate: {
          sourceLanguage: sourceLanguage,
          destinationLanguage: destinationLanguage,
          value: value
        }
      }
    })
})
