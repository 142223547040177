import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { useTranslate } from 'polyglot-react-redux-sdk'

import { actions as authActions, selectors as authSelectors } from 'store/reducers/auth'

import { clickableElement } from 'utils/accessibility'

import logo from 'assets/images/logo-inverted.svg'

const LeftSidebar = () => {
  const t = useTranslate('sidebar')

  const authenticated = useSelector(authSelectors.isAuthenticated)

  const dispatch = useDispatch()
  const logout = useCallback(() => dispatch(authActions.logout()), [dispatch])

  const className = ['left-sidebar-component', authenticated && 'authenticated'].filter((c) => c).join(' ')

  return (
    <div className={className}>
      { authenticated &&
        <>
          <Link to={{ pathname: '/' }}>
            <img src={logo} alt="logo" />
          </Link>

          { false &&
            <div onClick={logout} className="logout-button" { ...clickableElement(t('logout')) }>
              <div className="logout-outer">
                <div className="logout-inner"></div>
              </div>
            </div>
          }
        </>
      }
    </div>
  )
}

export default LeftSidebar
