import React, { useMemo } from 'react'
import Dropdown from 'react-dropdown'

const DP = ({ options, value, onChange, placeholder, type='disabled', disabled }) => {
  const controlClass = useMemo(() => [
    'dropdown-control',
    disabled && 'disabled',
  ].filter(c => c).join(' '), [type, disabled])

  const menuClass = useMemo(() => [
    'dropdown-menu',
    disabled && 'disabled',
  ].filter(c => c).join(' '), [type, disabled])

  return (
    <Dropdown
      className="dropdown-component"
      controlClassName={controlClass}
      placeholderClassName="dropdown-placeholder"
      menuClassName={menuClass}
      arrowClassName="dropdown-arrow"
      options={options} 
      onChange={onChange} 
      value={value}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

export default DP
