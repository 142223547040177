import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslate } from 'polyglot-react-redux-sdk'

import { actions as authActions, selectors as authSelectors } from 'store/reducers/auth'

import TextField from 'components/TextField'
import Button from 'components/Button'

import logo from 'assets/images/logo.svg'
import logoFull from 'assets/images/logo-full.svg'

const AcceptInvitation = ({ token }) => {
  const t = useTranslate('acceptinvitation')

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const dispatch = useDispatch()
  const accept = useCallback(() => (
    dispatch(authActions.acceptInvitation(token, firstName, lastName, password, passwordConfirmation))
  ), [dispatch, token, firstName, lastName, password, passwordConfirmation])

  const acceptInvitationLoading = useSelector(authSelectors.getAcceptInvitationLoading)
  const acceptInvitationError = useSelector(authSelectors.getAcceptInvitationError)

  const handleChangeFirstName = useCallback((value) => {
    setFirstName(value.target.value)
  }, [])

  const handleChangeLastName = useCallback((value) => {
    setLastName(value.target.value)
  }, [])

  const handleChangePassword = useCallback((value) => {
    setPassword(value.target.value)
  }, [])

  const handleChangePasswordConfirmation = useCallback((value) => {
    setPasswordConfirmation(value.target.value)
  }, [])

  const handleSubmitForm = useCallback(() => {
    accept()
  }, [accept])

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleSubmitForm()
    }
  }, [handleSubmitForm])

  return (
    <div className="accept-invitation-page row" onKeyPress={handleKeyPress}>
      <div className="left-section"></div>

      <div className="center-section">
        <img src={logo} alt="logo" className="logo"/>

        <div className="form">
          <div className="field">
            <TextField
              value={firstName}
              type="text"
              placeholder={t('firstname.placeholder')}
              error={acceptInvitationError}
              onChange={handleChangeFirstName} />
          </div>

          <div className="field">
            <TextField
              value={lastName}
              type="text"
              placeholder={t('lastname.placeholder')}
              error={acceptInvitationError}
              onChange={handleChangeLastName} />
          </div>

          <div className="field">
            <TextField
              value={password}
              type="password"
              placeholder={t('password.placeholder')}
              error={acceptInvitationError}
              onChange={handleChangePassword} />
          </div>

          <div className="field">
            <TextField
              value={passwordConfirmation}
              type="password"
              placeholder={t('passwordconfirmation.placeholder')}
              error={acceptInvitationError}
              onChange={handleChangePasswordConfirmation} />
          </div>

          <div className="form-error">
            { acceptInvitationError &&
              <p>{t(`error.${acceptInvitationError.status}`)}</p>
            }
          </div>

          <div className="button-container">
            <Button text={t('submit')} action={handleSubmitForm} type="black" loading={acceptInvitationLoading} />
          </div>
        </div>

        <img src={logoFull} alt="full logo" className="logo-full" />
      </div>

      <div className="right-section"></div>
    </div>
  )
}

export default AcceptInvitation
