import { createSelector } from 'reselect'

import { getLanguagesEntities } from '../entities/selectors'

export const getLanguages = createSelector(
  (state) => state.languages.languages,
  getLanguagesEntities,
  (languages, entities) => languages.map((o) => entities[o])
)

export const getAllLanguages = createSelector(
  (state) => state.languages.languages,
  getLanguagesEntities,
  (languages, entities) => languages.map((o) => entities[o])
)

export const makeGetLanguage = () => (
  createSelector(
    getLanguagesEntities,
    (_, languageId) => languageId,
    (entities, id) => entities[id]
  )
)

export const getDropdownAvailableLanguages = createSelector(
  (state) => state.languages.languages,
  (_, projectLanguages) => projectLanguages,
  getLanguagesEntities,
  (languages, projectLanguages, entities) => languages
    .filter(l => !projectLanguages.includes(l))
    .map(o => ({
      value: entities[o].id,
      label: entities[o].name
    }))
)

export const getDropdownProjectLanguages = createSelector(
  (_, projectLanguages) => projectLanguages,
  getLanguagesEntities,
  (projectLanguages, entities) => projectLanguages
    .concat()
    .sort()
    .map(o => ({
      value: entities[o].id,
      label: entities[o].name
    }))
)
