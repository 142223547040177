import React from 'react'
import { useSelector } from 'react-redux'

// import { useTranslate } from 'polyglot-react-redux-sdk'

import { selectors as authSelectors } from 'store/reducers/auth'

import help from 'assets/images/help.svg'

const RighSidebar = () => {
  // const t = useTranslate('sidebar')

  const authenticated = useSelector(authSelectors.isAuthenticated)

  return (
    <div className="right-sidebar-component">
      { authenticated &&
        <>
        <div className="help-button">
          <a className="help-outer" href="mailto:support@gaspardbruno.com" tager="_blank">
            <img  src={help} alt="logo" />
          </a>
        </div>
        </>
    }
  </div>
)
}

export default RighSidebar
