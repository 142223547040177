import produce from 'immer'

import * as actionTypes from './actionTypes'


const initialState = {
  projects: [],
  projectsLoading: false,
  projectsError: null,
  projectLoading: false,
  projectError: null,
  createProjectError: null,
  createProjectLanguage: false,
  createProjectLanguageError: null,
  deleteProjectLanguage: false,
  deleteProjectLanguageError: null,
  updateOutputMode: false,
  updateOutputModeError: null,
  presignedUrlLoading: false,
  presignedUrl: "",
  publishingStrings: false,
}

const reducer = (state = initialState, action = {}) => {
  return produce(state, draft => {
    switch (action.type) {
      // GET PROJECTS
      case actionTypes.GET_PROJECTS:
        draft.projectsLoading = true
        break
      case actionTypes.GET_PROJECTS_SUCCESS:
        draft.projectsLoading = false
        draft.projects = action.result.data.map((d) => d.id)
        break
      case actionTypes.GET_PROJECTS_FAIL:
        draft.projectsLoading = false
        draft.projectsError = { status: action.error.status }
        break

      // GET PROJECT
      case actionTypes.GET_PROJECT:
        draft.projectLoading = true
        break
      case actionTypes.GET_PROJECT_SUCCESS:
        let fetchedProject = draft.projects.find(projId => projId === action.result.data.id)

        draft.projectLoading = false
        if (!fetchedProject) {
          draft.projects = [action.result.data.id].concat(draft.projects)
        }
        break
      case actionTypes.GET_PROJECT_FAIL:
        draft.projectLoading = false
        draft.projectError = { status: action.error.status }
        break

      // CREATE PROJECT
      case actionTypes.CREATE_PROJECT:
        draft.createProject = true
        break
      case actionTypes.CREATE_PROJECT_SUCCESS:
        draft.createProject = false
        draft.projects = [action.result.data.id].concat(draft.projects)
        break
      case actionTypes.CREATE_PROJECT_FAIL:
        draft.createProject = false
        draft.createProjectError = { status: action.error.status }
        break

      // DELETE PROJECT
      case actionTypes.DELETE_PROJECT:
        draft.deleteProjectLoading = true
        break
      case actionTypes.DELETE_PROJECT_SUCCESS:
        draft.deleteProjectLoading = false
        draft.projects = draft.projects.filter(projectId => projectId !== action.params.projectId)
        break
      case actionTypes.DELETE_PROJECT_FAIL:
        draft.deleteProjectLoading = false
        draft.deleteProjectError = { status: action.error.status }
        break

      // CREATE PROJECT LANGUAGE
      case actionTypes.CREATE_PROJECT_LANGUAGE:
        draft.createProjectLanguage = true
        break
      case actionTypes.CREATE_PROJECT_LANGUAGE_SUCCESS:
        draft.createProjectLanguage = false
        draft.createProjectLanguageError = null
        break
      case actionTypes.CREATE_PROJECT_LANGUAGE_FAIL:
        draft.createProjectLanguage = false
        draft.createProjectLanguageError = { status: action.error.status }
        break

      // DELETE PROJECT LANGUAGE
      case actionTypes.DELETE_PROJECT_LANGUAGE:
        draft.deleteProjectLanguage = true
        break
      case actionTypes.DELETE_PROJECT_LANGUAGE_SUCCESS:
        draft.deleteProjectLanguage = false
        draft.deleteProjectLanguageError = null
        break
      case actionTypes.DELETE_PROJECT_LANGUAGE_FAIL:
        draft.deleteProjectLanguage = false
        draft.deleteProjectLanguageError = { status: action.error.status }
        break

      // UPDATE OUTPUT MODE
      case actionTypes.UPDATE_OUTPUT_MODE:
        draft.updateOutputMode = true
        break
      case actionTypes.UPDATE_OUTPUT_MODE_SUCCESS:
        draft.updateOutputMode = false
        draft.updateOutputModeError = null
        break
      case actionTypes.UPDATE_OUTPUT_MODE_FAIL:
        draft.updateOutputMode = false
        draft.updateOutputModeError = { status: action.error.status }
        break
      case actionTypes.GET_PRESIGNED_URL:
        draft.presignedUrlLoading = true
        break
      case actionTypes.GET_PRESIGNED_URL_SUCCESS:
        draft.presignedUrlLoading = false
        draft.presignedUrl = action.result.meta.url
        break
      case actionTypes.GET_PRESIGNED_URL_FAIL:
        draft.presignedUrlLoading = false
        draft.projectsError = { status: action.error.status }
        break
      case actionTypes.PUBLISH_STRINGS:
        draft.publishStrings = true
        break
      case actionTypes.PUBLISH_STRINGS_SUCCESS:
        draft.publishStrings = false
        break
      case actionTypes.PUBLISH_STRINGS_FAIL:
        draft.publishStrings = false
        draft.projectsError = { status: action.error.status }
        break
      default:
        break
    }
  })
}

export default reducer
