import * as actionTypes from './actionTypes'

export const getLocalizedStrings = (projectId) => ({
  types: [
    actionTypes.GET_LOCALIZED_STRINGS,
    actionTypes.GET_LOCALIZED_STRINGS_SUCCESS,
    actionTypes.GET_LOCALIZED_STRINGS_FAIL,
  ],
  promise: (client) => client.get(`localized_strings?project_id=${projectId}`)
})

export const createLocalizedString = (key, projectId) => ({
  types: [
    actionTypes.CREATE_LOCALIZED_STRING,
    actionTypes.CREATE_LOCALIZED_STRING_SUCCESS,
    actionTypes.CREATE_LOCALIZED_STRING_FAIL,
  ],
  promise: (client) => client.post(
    'localized_strings',
    { data: { localized_string: { key, project_id: projectId } } })
})

export const updateLocalizedString = (key, localizedStringId) => ({
  types: [
    actionTypes.UPDATE_LOCALIZED_STRING,
    actionTypes.UPDATE_LOCALIZED_STRING_SUCCESS,
    actionTypes.UPDATE_LOCALIZED_STRING_FAIL,
  ],
  promise: (client) => client.put(
    `localized_strings/${localizedStringId}`,
    { data: { localized_string: { key } } })
})

export const deleteLocalizedString = (localizedStringId) => ({
  types: [
    actionTypes.DELETE_LOCALIZED_STRING,
    actionTypes.DELETE_LOCALIZED_STRING_SUCCESS,
    actionTypes.DELETE_LOCALIZED_STRING_FAIL,
  ],
  promise: (client) => client.delete(`localized_strings/${localizedStringId}`),
})
