import produce from 'immer'

import * as actionTypes from './actionTypes'


const initialState = {
  menuCollapsed: {
    project: false
  }
}


const reducer = (state = initialState, action = {}) => {
  return produce(state, draft => {
    switch (action.type) {
      // Menu
      case actionTypes.TOGGLE_MENU:
        draft.menuCollapsed[action.payload.menu] = !draft.menuCollapsed[action.payload.menu]
        break

      default:
        break
    }
  })
}

export default reducer
