import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { usePolyglot } from 'polyglot-react-redux-sdk'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css';

import { actions as languagesActions } from 'store/reducers/languages'
import { actions as authActions, selectors as authSelectors } from 'store/reducers/auth'

import Router from 'navigation/Router'

import AppLoading from 'pages/AppLoading'

import LeftSidebar from 'components/LeftSidebar'
import RightSidebar from 'components/RightSidebar'

import defaultPhrases from 'utils/defaultPhrases'

import 'styles/main.scss'

const isDev = process.env.REACT_APP_ENV === 'dev';
const App = () => {
  const { phrasesLoaded } = usePolyglot({
    defaultUrl: 'https://d8wlqn7pvlrac.cloudfront.net/c81e728d9d4c2f636f067f89cc14862c/polyglot-web/all.json',
    defaultLocale: 'en-us',
    useDefaultPhrases: isDev,
    defaultPhrases,
  })

  const dispatch = useDispatch()

  const [pathName, setPathname] = useState('')

  useEffect(() => {
    dispatch(languagesActions.getLanguages())
  }, [])

  useEffect(() => {
    if (Cookies.get('authorization-header-frontend')) {
      dispatch(authActions.me())
    }
  }, [])

  const meLoading = useSelector(authSelectors.getMeLoading)
  const authenticated = useSelector(authSelectors.isAuthenticated)

  const appReady = useMemo(() => phrasesLoaded && !meLoading, [phrasesLoaded, meLoading])

  const handleRoutePathName = (pathName) => {
    setPathname(pathName)
  }

  const shouldDisplayRightSideBar = () => {
    const blackList = new RegExp('/projects/[^s]+/people')
    const landing = pathName === '/' || !authenticated
    // const blackList1 = new RegExp('/projects/[^s]+/translations')

    // const shouldDisplay = !blackList.test(pathName) && !blackList1.test(pathName)
    const shouldDisplay = !blackList.test(pathName) && !landing

    return shouldDisplay
  }

  const shouldDisplayLeftSideBar = () => {
    const landing = pathName === '/' || !authenticated

    const shouldDisplay = !landing

    return shouldDisplay
  }

  return (
    <BrowserRouter>
      { appReady ?
        <>
          <div className="app">
            { shouldDisplayLeftSideBar() &&
              <LeftSidebar />
            }

            <div className="app-content">
              <Router setRoutePathName={handleRoutePathName} />
            </div>

            { shouldDisplayRightSideBar() &&
              <RightSidebar />
            }

            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover />
          </div>
        </>
        :
        <AppLoading />
      }
    </BrowserRouter>
  );
}

export default App
