import React from 'react'
import Loader from 'react-loader-spinner'

const LoaderComponent = () => (
  <div className="loader-component">
    <Loader
      type="TailSpin"
      color="#313131"
      height={80}
      width={80}
    />
  </div>
)

export default LoaderComponent
