import { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions as projectsActions, selectors as projectsSelectors } from 'store/reducers/projects'

const useFetchProject = (projectFetched, projectSlug) => {
  const [projectId, setProjectId] = useState(projectFetched || null)

  const dispatch = useDispatch()
  const getProject = useCallback(projectSlug =>
      dispatch(projectsActions.getProject(projectSlug), [dispatch]))

  const makeProject = useMemo(projectsSelectors.makeGetProject, [])
  const project = useSelector((state) => makeProject(state, projectId))

  useEffect(() => {
    getProject(projectSlug).then((project) => {
      setProjectId(project.data.id)
    })
  }, [])

  return project
}

export default useFetchProject
